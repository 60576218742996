import React, { useEffect, useState } from 'react'
import { Table, Button } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
// import Button from 'react-bootstrap/Button'
import MaterialTable from "material-table";
import useGetCategoriesAPI from '../../../CustomHooks/getCategories';
import authHeader from '../../../services/auth-header';


export default function List() {

    const [APIData, setAPIData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowList, setModalShowList] = useState(false);
    const history = useHistory();

    const setDataModal = (data) => {
        setModalShow(true);
        // setPlName(data);
        setModalData(data);
        // console.log(data);
    }

    const setData = (data) => {
        console.log(data);
        let { id, Name } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('Name', Name);
    
        history.push('/categories/update')
    }

    const getData = () => {
        axios.get(`https://mcqueeninc.net/api/category`, {headers: authHeader()})
            .then((response) => {
                setAPIData(response.data);
            })
    }

    const onDelete = (id) => {
        axios.delete(`https://mcqueeninc.net/api/category/${id}`, {headers: authHeader()})
            .then((response) => {
                console.log(response.data);
                getData();
            })
    }

    useEffect(() => {
        getData();
    }, [])

    return (

        <div>
            <div>
                <Link to={'/categories/add'}>
                    <Button positive>
                        Crear categoria
                    </Button>
                </Link>
            </div>
            <div>
                <br></br>
            </div>
            <MaterialTable
                title="Lista de Categorias"
                columns={[
                    { title: 'Nombre', field: 'Name' },

                ]}
                data={APIData}
                actions={[

                    {
                        icon: 'edit',
                        tooltip: 'Editar lista de reproduccion',
                        onClick: (event, rowData) => setData(rowData)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Eliminar lista de reproduccion',
                        onClick: (event, rowData) => onDelete(rowData.id)
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    }
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}'
                    },
                    toolbar: {
                        nRowsSelected: '{0} row(s) selected'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No contenido para mostrar',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    },
                    pagination: {
                        labelRowsSelect: 'lineas'
                    },
                    toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                    }
                }}>

            </MaterialTable>
        </div>
    )
}