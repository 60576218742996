import React, { useState } from 'react'
import {
    TextField,
    Autocomplete
}
    from '@mui/material';




const SongAutocomplete = (props) => {
    const [inputValue, setInputValue] = useState("");
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        if (inputValue.length > 0) {
            setOpen(true);
        }
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };
    return (
        <div>
            <Autocomplete
                id="Songs"
                sx={{
                    display: 'inline-block',
                    // color: 'white',
                    width: 200,
                    '& input': {
                        width: 200,
                        // bgcolor: 'background.paper',
                        fontStyle: { color: 'white' },

                        color: 'white'
                    }
                }}
                open={open}
                onOpen={handleOpen}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                options={props.allSongs ? props.allSongs : []}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                getOptionLabel={(Songs) => Songs.name ? Songs.name : ''}
                //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                onChange={(event, value) => value ? props.onAddAudio(value) : ''}
                renderInput={(params) => <TextField {...params} label="Busca tu canción" />}
            />
        </div>
    )
}

export default SongAutocomplete