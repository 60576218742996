import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import AuthVerify from "./common/auth-verify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './components/theme';
import AuthService from "./services/auth.service";
import { PrivateRoute } from "./components/Login/privateRoute";
import Login from "./components/Login/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import Player from "./components/music-player"
import SongList from "./components/Songs/ListSongs"
import UpdateSong from "./components/Songs/Update"
import Details from "./components/Songs/Details"
import CreatePlaylist from "./components/Playlist/createPlaylist"
import ListPlaylist from "./components/Playlist/List"
import UpdatePlaylist from "./components/Playlist/Update"
import User from "./components/Users//List"
import RegisterUser from "./components/Users/RegisterUser"
import UpdateUser from "./components/Users/Update"
import Category from "./components/Category/List/List"
import AddCategory from "./components/Category/Add/Add"
import UpdateCategory from "./components/Category/Update/Update"
import Dragable from "./components/DragableSong/Drag"
import CreateAd from "./components/Ads/Create"
import ListAds from "./components/Ads/List"
import EventBus from "./common/EventBus";
import FileUploadPage from "./components/Songs/FileUploadPage";
import UpdateAd from "./components/Ads/Update"
import UserTracking from "./components/UserTracking/List";
import AdsStatistics from "./components/AdsStatistics/Statistics";
import SortableTable from "./components/SortableTable/SortableTable";
import SubAds from "./components/SubAnuncios/Subads"
import ListVideoImageAds from "./components/VideoImageAds/List"
import CreateVideoImageAds from "./components/VideoImageAds/Create"
import UpdateMultimediaAd from "./components/VideoImageAds/Update"

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      isOpen: false,
      videoPlayer: false
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
      console.log(user);
      if (user.userType) {
        if (user.userType === 'video')
          this.setState({ videoPlayer: true })
      }
    }
    else {
      // console.log(user);

    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showAdminBoard } = this.state;

    return (
      <>
        <div>
          <ThemeProvider theme={theme}>
            {<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Container>
                {!this.state.videoPlayer && <Navbar.Brand href="/Player">Music Player</Navbar.Brand>}
                {this.state.videoPlayer && <Navbar.Brand href="/subads">Video Player</Navbar.Brand>}
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  {showAdminBoard &&
                    <Nav className="me-auto">
                      <Nav.Link href="/songs">Canciones</Nav.Link>
                      <Nav.Link href="/listPlaylist">Listas de Reproducción</Nav.Link>
                      <Nav.Link href="/categories">Categorias</Nav.Link>
                      <Nav.Link href="/users">Usuarios</Nav.Link>
                      <Nav.Link href="/ads">Anuncios</Nav.Link>
                      <Nav.Link href="/ads/multimedia">Anuncios multimedia</Nav.Link>
                      <Nav.Link href="/subads">Play Anuncios</Nav.Link>
                      {/* <Nav.Link href="/statistics">Estadisticas</Nav.Link> */}
                    </Nav>
                  }
                  {currentUser &&
                    <Nav variant="pills" className="me-auto">
                      <NavDropdown onMouseEnter={this.handleOpen}
                        onMouseLeave={this.handleClose}
                        menuVariant="dark" title="Estadisticas" id="nav-dropdown"
                        show={this.state.isOpen}
                      >
                        {showAdminBoard &&
                          <NavDropdown.Item href="/usertracking">Seguimiento de usuarios</NavDropdown.Item>
                        }
                        <NavDropdown.Item href="/statistics">Anuncios</NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  }
                  <Nav>
                    {currentUser &&

                      <Nav.Link>{currentUser.username}</Nav.Link>
                    }
                    {!currentUser ? (
                      <Nav.Link href="/login">Iniciar sesión</Nav.Link>
                    ) : (
                      <div>
                        {/* <Navbar.Text>Klk mio</Navbar.Text> */}

                        <Nav.Link href="/login" onClick={this.logOut}>Cerrar sesión</Nav.Link>

                      </div>
                    )
                    }
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>}

            <div className="container mt-3">
            
              <Switch>
                {/* ***************************************************************** */}
                {!this.state.videoPlayer && <PrivateRoute exact path={["/", "/player"]} component={Player}>
                </PrivateRoute>}
                {this.state.videoPlayer && <PrivateRoute exact path={["/", "/subads"]} component={SubAds}>
                </PrivateRoute>}
                {/* <PrivateRoute exact path="/Player" component={Player} /> */}
                {/* ***************************************************************** */}
                <Route exact path="/users" component={User} />
                <Route exact path="/users/register" component={RegisterUser} />
                <Route exact path="/users/update" component={UpdateUser} />
                {/* ***************************************************************** */}
                <Route exact path="/categories" component={Category} />
                <Route exact path="/categories/add" component={AddCategory} />
                <Route exact path="/categories/update" component={UpdateCategory} />
                <Route exact path="/ads" component={ListAds} />
                <Route exact path="/ads/create" component={CreateAd} />
                <Route exact path="/ads/update" component={UpdateAd} />
                <Route exact path="/ads/multimedia" component={ListVideoImageAds} />
                <Route exact path="/ads/multimedia/create" component={CreateVideoImageAds} />
                <Route exact path="/ads/multimedia/update" component={UpdateMultimediaAd} />
                <Route exact path="/subads" component={SubAds}></Route>
                {/* ***************************************************************** */}
                <Route exact path="/listPlaylist" component={ListPlaylist} />
                <Route exact path="/playlist/sortSongs" component={Dragable} />
                <Route exact path="/createPlaylist" component={CreatePlaylist} />
                <Route exact path="/updatePlaylist" component={UpdatePlaylist} />
                {/* ***************************************************************** */}
                <Route exact path="/songs" component={SongList} />
                <Route exact path="/createSong" component={FileUploadPage} />
                <Route exact path="/update" component={UpdateSong} />
                <Route exact path="/details" component={Details} />
                {/* ***************************************************************** */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/usertracking" component={UserTracking} />
                <Route exact path="/statistics" component={AdsStatistics} />
                <Route exact path="/sortableTable" component={SortableTable} />
              </Switch>
            </div>
          </ThemeProvider>
          <AuthVerify logOut={this.logOut} />
        </div>

      </>
    );
  }
}

export default App;
