import React, { useState, useRef, useEffect } from 'react';
import authHeader from '../../services/auth-header';
import classes from './users.module.css';
import Card from '../UI/Card';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Toast from 'react-bootstrap/Toast'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Municipios from '../../JsonFiles/municipios.json'
import Provincias from '../../JsonFiles/provincias.json'
import Sectores from '../../JsonFiles/sectores.json'
export default function Update() {

    const [RoleApi, setAPIData] = useState([{
        id: 99,
        Name: "Seleccionar Rol"
    }]);
    const [defaultRoles]
        = useState([{ label: "User", id: "user" }, { label: "Moderador", id: "moderator" }, { label: "Admin", id: "admin" }]);

    const [defaultTypes] = useState([{ label: "Reproductor de Musica", id: "music" }, { label: "Reproductor de Videos", id: "video" }]);
    const [playlist, setPlaylist] = useState(0);
    const [Categories, SetAPICategories] = useState([]);
    const [fieldProvincias, setProvincias] = useState([]);
    const [fieldMunicipios, setMunicipios] = useState([]);
    const [fieldSectores, setSectores] = useState([]);

    const [valueProvincias, setValProvincias] = useState('');
    const [valueMunicipios, setValMunicipios] = useState('');
    const [valueSectores, setValSectores] = useState('');

    const [id, setID] = useState(null);
    const [Username, setUsername] = useState('');
    const [Fullname, setFullname] = useState('');
    const [Roles, setRoles] = useState([]);
    const [Category, setCateg] = useState([]);
    const [Email, setEmail] = useState('');
    const [category, setCategory] = useState(0);
    const [userType, setUserType] = useState('');

    const userNameInputRef = useRef();
    const fullNameInputRef = useRef();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const history = useHistory();

    function submitHandler(event) {
        event.preventDefault();
        handleSubmission();
    }

    const handleChangeRoles = (event) => {
        console.log(Roles);
        setRoles([{ name: [event.target.value] }]);
    }
    const handleChangePlaylist = (event) => {
        setPlaylist(event.target.value);
    }
    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    }

    const getPlaylistData = () => {
        axios.get(`https://mcqueeninc.net/api/playlist`, { headers: authHeader() })
            .then((response) => {
                setAPIData(response.data);
                getCategoryData();
            });
    }

    const getCategoryData = () => {
        axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader() })
            .then((response) => {
                SetAPICategories(response.data);
            })
    }

    const handleSubmission = () => {
        console.log(userType);
        var updateRequest = {
            username: userNameInputRef.current.value,
            email: emailInputRef.current.value,
            fullname: fullNameInputRef.current.value,
            userType: userType,
            ProvinciaId: valueProvincias,
            MunicipioId: valueMunicipios,
            DistritoMunicipalId: valueSectores
        };

        if (Roles) {
            updateRequest = {
                ...updateRequest,
                roles: Roles
            }
        }

        if (playlist) {
            updateRequest = {
                ...updateRequest,
                playlistid: playlist
            }
        }
        if (category) {
            updateRequest = {
                ...updateRequest,
                categoryId: category
            }

        }
        if (passwordInputRef.current.value) {
            updateRequest = {
                ...updateRequest,
                password: passwordInputRef.current.value
            }
        };

        let headerCode = authHeader();

        var requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': headerCode["x-access-token"]
            },
            body: JSON.stringify(updateRequest),
            redirect: 'follow'
        };
        // console.log(updateRequest);
        fetch(`https://mcqueeninc.net/api/users/${id}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                history.goBack();
            }
            )
            .catch(error => console.log('error', error));

    }

    const setInit = () => {
        setID(localStorage.getItem('ID'));
        setUsername(localStorage.getItem('Username'));
        setEmail(localStorage.getItem('Email'));
        setRoles([{ name: localStorage.getItem('Roles') }]);
        setUserType(localStorage.getItem('UserType'));
        setPlaylist(localStorage.getItem('PlaylistId'));
        setFullname(localStorage.getItem('Fullname'));
        setCateg(localStorage.getItem('CategoryId'));
        setProvincias(localStorage.getItem('ProvinciaId'));
        setValProvincias(localStorage.getItem('ProvinciaId'));
        setValMunicipios(localStorage.getItem('MunicipioId'));
        setMunicipios(localStorage.getItem('MunicipioId'));
        setSectores(localStorage.getItem('SectorId'));
        setValSectores(localStorage.getItem('SectorId'));
    }

    useEffect(() => {
        setInit();
        console.log(localStorage.getItem('MunicipioId'));
        console.log(Municipios);
        getPlaylistData();

    }, []);

    if (Roles.length > 0 && Categories.length > 0) {
        return (
            <Card>
                <Box
                    id='userForm'
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={submitHandler}
                >
                    <div>
                        <div>
                            <TextField id="outlined-basic" label="Nombre de usuario" value={Username} inputRef={userNameInputRef} variant="outlined" />
                        </div>
                        <div>
                            <TextField id="outlined-basic" label="Nombre completo" value={Fullname} inputRef={fullNameInputRef} variant="outlined" />
                        </div>
                        <div>
                            <TextField id="outlined-basic" label="Correo electronico" value={Email} inputRef={emailInputRef} variant="outlined" />
                        </div>
                        <div>
                            <TextField id="outlined-basic" type='password' label="Contraseña" inputRef={passwordInputRef} variant="outlined" />
                        </div>
                        <div>
                            <Autocomplete
                                disablePortal
                                id="Roles"
                                options={defaultRoles}
                                sx={{ width: 300 }}
                                getOptionLabel={defaultRoles => defaultRoles.label}
                                value={defaultRoles[defaultRoles.findIndex(item => item.id == Roles[0].name)]}
                                onChange={(event, value) => { setRoles([{ name: value.id }]) }}
                                renderInput={(params) => <TextField {...params} label="Rol" />}
                            />
                        </div>
                        <Autocomplete
                            disablePortal
                            id="Types"
                            options={defaultTypes}
                            sx={{ width: 300 }}
                            getOptionLabel={defaultTypes => defaultTypes.label}
                            value={defaultTypes[defaultTypes.findIndex(item => item.id == userType)]}
                            onChange={(event, value) => value ? setUserType(value.id) : setUserType('')}
                            renderInput={(params) => <TextField {...params} label="Tipo de usuario" />}
                        />

                        <div>
                            <Autocomplete
                                disablePortal
                                id="lreproduccion"
                                options={RoleApi}
                                sx={{ width: 300 }}
                                getOptionLabel={RoleApi => RoleApi.Name}
                                value={RoleApi[RoleApi.findIndex(item => item.id == playlist)]}
                                onChange={(event, value) => setPlaylist(value.id)}
                                renderInput={(params) => <TextField {...params} label="Lista de reproducción" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="category"
                                options={Categories}
                                sx={{ width: 300 }}
                                getOptionLabel={Categories => Categories.Name}
                                value={Categories[Categories.findIndex(item => item.id == Category)]}
                                onChange={(event, value) => setCategory(value.id)}
                                renderInput={(params) => <TextField {...params} label="Categoria" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="provincias"
                                options={Provincias}
                                sx={{ width: 300 }}
                                getOptionLabel={Provincias => Provincias.provincia}
                                value={Provincias[Provincias.findIndex(item => item.provincia_id == fieldProvincias)]}
                                onChange={(event, value) => {
                                    setValProvincias(value.provincia_id)
                                    setProvincias(value.provincia_id)

                                    if (value.provincia_id != fieldProvincias) {
                                        setMunicipios('');
                                    }

                                }}
                                renderInput={(params) => <TextField {...params} label="Provincia" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="municipios"
                                options={Municipios.filter(item => {
                                    if (valueProvincias) {
                                        return item.provincia_id == valueProvincias;
                                    } else {
                                        return item;
                                    }
                                })}
                                sx={{ width: 300 }}
                                getOptionLabel={(Municipios) => Municipios.minicipio ? Municipios.minicipio : ''}
                                value={Municipios[Municipios.findIndex(item => item.minicipio_id == fieldMunicipios)]}
                                onChange={(event, value) => {
                                    if (value == null) {
                                        setMunicipios('');
                                    }
                                    else {
                                        setMunicipios(value.minicipio_id);
                                        setValMunicipios(value.minicipio_id);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Municipio" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="sectores"
                                options={Sectores.filter(item => {

                                    if (valueMunicipios) {
                                        return item.municipio_id == valueMunicipios;
                                    } else {
                                        return '';
                                    }
                                })}
                                renderOption={(props, option) => (

                                    <li {...props} key={option.sector_id}>
                                        {option.sector}
                                    </li>
                                )}
                                sx={{ width: 300 }}
                                getOptionLabel={(Sectores) => Sectores.sector ? Sectores.sector : ''}
                                value={Sectores[Sectores.findIndex(item => item.sector_id == fieldSectores)]}

                                onChange={(event, value) => {
                                    if (value == null) {
                                        setSectores('');
                                    } else {
                                        setSectores(value.sector_id);
                                        setValSectores(value.sector_id);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Sector" />}
                            />
                        </div>

                        <div className={classes.actions}>

                            <button>Actualizar usuario</button>
                        </div>
                        {/* </form> */}
                    </div>
                </Box>
            </Card>
        )
    } else {
        return <div>Loading...</div>
    }

}

