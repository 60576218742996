import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import {
    TextField,
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Button,
    Input,
    InputLabel,
    Select
}
    from '@mui/material';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Fab from '@mui/material/Fab';
import { red } from '@mui/material/colors';


const IntensityAdsZone = (props) => {


    const [inputFields, setInputFields] =
        useState((props.intensityByZone && props.intensityByZone != null) ?
            JSON.parse(props.intensityByZone) : [{ provinciaId: '', municipioId: '', sectorId: '', weight: '' }]);
    console.log(inputFields);
    const handleFormChange = (index, event, name, valueName) => {
        let data = [...inputFields];
        console.log(event);
        if (event && !event.target)
            data[index][name] = event.id;

        if (event && event.target)
            data[index][name] = event.target.value;

        if (valueName) {
            if (event)
                data[index][name] = event[valueName];

        }


        if (!event)
            data[index][name] = '';

        console.log(data);
        setInputFields(data);
        props.handleIntensityInfo(data);
    }

    const addFields = (e) => {
        e.preventDefault();
        let newField = { userId: '', weight: '' };

        setInputFields([...inputFields, newField]);
    }

    const removeFields = (index) => {
        console.log(index);
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
        props.handleIntensityInfo(data);
    }
    return (
        <div>

            {/* <form autoComplete="off"
                noValidate> */}
            <Card>
                <CardHeader
                    subheader="Control de intensidad por zona"
                    title="Intensidad"
                />
                <Divider />
                <CardContent>

                    {inputFields.map((input, index) => (
                        <div key={index} id={index}>
                            <Grid
                                container
                                spacing={1}>
                                <Grid
                                    item
                                // md={4}
                                >
                                    <Autocomplete

                                        disablePortal
                                        id="Provincias"
                                        options={props.Provincias}
                                        sx={{ width: 200 }}
                                        getOptionLabel={(Provincias) => Provincias.provincia ? Provincias.provincia : ''}
                                        value={props.Provincias[props.Provincias.findIndex(item => item.provincia_id == input.provinciaId)] || null}
                                        onChange={(event, value) => handleFormChange(index, value, 'provinciaId', 'provincia_id')}
                                        renderInput={(params) => <TextField {...params} label="Provincia" />} />

                                </Grid>
                                <Grid
                                    item
                                // md={4}
                                // xs={2}

                                >
                                    <Autocomplete

                                        disablePortal
                                        id="Municipios"
                                        // options={props.Municipios}
                                        sx={{ width: 200 }}
                                        getOptionLabel={(Municipios) => Municipios.minicipio ? Municipios.minicipio : ''}
                                        value={props.Municipios[props.Municipios.findIndex(item => item.minicipio_id == input.municipioId)] || null}
                                        options={props.Municipios.filter(item => {
                                            if (input.provinciaId) {
                                                return item.provincia_id === input.provinciaId;
                                            } else {
                                                return '';
                                            }
                                        })}

                                        onChange={(event, value) => handleFormChange(index, value, 'municipioId', 'minicipio_id')}
                                        renderInput={(params) => <TextField {...params} label="Municipio" />} />

                                </Grid>
                                <Grid
                                    item
                                >
                                    <Autocomplete

                                        disablePortal
                                        id="Sectores"
                                        // options={props.Sectores}
                                        options={props.Sectores.filter(item => {
                                            if (input.municipioId) {
                                                return item.municipio_id === input.municipioId;
                                            } else {
                                                return '';
                                            }
                                        })}
                                        sx={{ width: 200 }}
                                        getOptionLabel={(Sectores) => Sectores.sector ? Sectores.sector : ''}
                                        value={props.Sectores[props.Sectores.findIndex(item => item.sector_id == input.sectorId)] || null}
                                        onChange={(event, value) => handleFormChange(index, value, 'sectorId', 'sector_id')}
                                        renderInput={(params) => <TextField {...params} label="Sector" />} />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Intensidad</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="weight"
                                            defaultValue={0}
                                            value={input.weight ? input.weight : 0}
                                            label="Intensidad"
                                            onChange={(event) => handleFormChange(index, event, 'weight')}
                                        >
                                            <MenuItem value={0}>
                                                <em>Ninguna</em>
                                            </MenuItem>
                                            <MenuItem value={25}>Baja</MenuItem>
                                            <MenuItem value={50}>Media</MenuItem>
                                            <MenuItem value={75}>Alta</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item
                                    md={1}
                                    xs={3}>
                                    <Fab size="small" color="primary" aria-label="remove" onClick={() => removeFields(index)}>
                                        <RemoveIcon />
                                    </Fab>
                                </Grid>

                            </Grid>

                        </div>

                    ))}

                    {/* <Button variant="outlined" size='small' startIcon={<AddIcon />} onClick={addFields}></Button> */}
                    <Fab size="small" color="primary" aria-label="add" onClick={addFields}>
                        <AddIcon />
                    </Fab>
                </CardContent>
            </Card>
            {/* </form> */}
        </div >
    )
}

export default IntensityAdsZone;