import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import {
    TextField,
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Button,
    Input,
    InputLabel,
    Select
}
    from '@mui/material';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Fab from '@mui/material/Fab';
import { red } from '@mui/material/colors';


const IntensityAdsForm = (props) => {

    console.log(props.intensityByUsers);
    const [inputFields, setInputFields] =
        useState((props.intensityByUsers && props.intensityByUsers != null) ? JSON.parse(props.intensityByUsers) : [{ userId: '', weight: '' }]);

    const handleFormChange = (index, event, name) => {
        let data = [...inputFields];

        if (!event.target)
            data[index][name] = event.id;

        if (event.target)
            data[index][name] = event.target.value;

        setInputFields(data);
        props.handleIntensityInfo(data);
    }

    const addFields = (e) => {
        e.preventDefault();
        let newField = { userId: '', weight: '' };

        setInputFields([...inputFields, newField]);
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
        props.handleIntensityInfo(data);
    }
    return (
        <div>
            <Card>
                <CardHeader
                    subheader="Control de intensidad por usuario"
                    title="Intensidad"
                />
                <Divider />
                <CardContent>

                    {inputFields.map((input, index) => {
                        return (
                            <div key={index} id={index}>
                                <Grid
                                    container
                                    spacing={1}>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <Autocomplete

                                            disablePortal
                                            id="users"
                                            options={props.Users}
                                            sx={{ width: 300 }}
                                            getOptionLabel={(Users) => Users.label ? Users.label : ''}
                                            value={props.Users[props.Users.findIndex(item => item.id == input.userId)]}
                                            onChange={(event, value) => handleFormChange(index, value, 'userId')}
                                            renderInput={(params) => <TextField {...params} label="Usuario" />}
                                        />

                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Intensidad</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="weight"
                                            defaultValue={0}
                                            value={input.weight ? input.weight : 0}
                                            label="Intensidad"
                                            onChange={(event) => handleFormChange(index, event, 'weight')}
                                        >
                                            <MenuItem value={0}>
                                                <em>Ninguna</em>
                                            </MenuItem>
                                            <MenuItem value={25}>Baja</MenuItem>
                                            <MenuItem value={50}>Media</MenuItem>
                                            <MenuItem value={75}>Alta</MenuItem>
                                        </Select>
                                        </FormControl>

                                    </Grid>

                                    <Grid item
                                        md={1}
                                        xs={3}>
                                        <Fab size="small" color="primary" aria-label="remove" onClick={() => removeFields(index)}>
                                            <RemoveIcon />
                                        </Fab>
                                    </Grid>

                                </Grid>

                            </div>

                        )
                    })}

                    {/* <Button variant="outlined" size='small' startIcon={<AddIcon />} onClick={addFields}></Button> */}
                    <Fab size="small" color="primary" aria-label="add" onClick={addFields}>
                        <AddIcon />
                    </Fab>
                </CardContent>
            </Card>

        </div >
    )
}

export default IntensityAdsForm;