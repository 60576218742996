import React, { useEffect, useState } from 'react'
import { Table, Button } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import PickSong from '../PickSongModal/PickSong.modal'
import SongList from './SongList.modal'
import SortSongs from '../SortSongsModal/SortSongs.modal'
import authHeader from '../../services/auth-header';
// import Button from 'react-bootstrap/Button'
import MaterialTable from "material-table";



export default function List() {

    const [APIData, setAPIData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowList, setModalShowList] = useState(false);
    const [modalShowSort, setModalShowSort] = useState(false);
    const history = useHistory();

    const setDataModal = (data) => {
        setModalShow(true);
        // setPlName(data);
        setModalData(data);
        // console.log(data);
    }

    const setDataSortModal = (data) => {
        setModalShowSort(true);
        setModalData(data);
    }

    const setData = (data) => {
        
        let { id, Name, CategoryId } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('Name', Name);
        localStorage.setItem('CategoryId', CategoryId)
    
        history.push('/updatePlaylist')
    }

    const setDataModalList = (data) => {
        setModalShowList(true);
        setModalData(data);
    }

    const getData = async () => {
        setAPIData([]);
        const PlData = await axios.get(`https://mcqueeninc.net/api/playlist`, {headers: authHeader()});
        const CategData = await axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader()});

        PlData.data.map(playlist => {
            let Name = CategData.data.find(item => item.id === playlist.CategoryId);
            console.log(CategData);

            if (!Name) {
                Name =  { Name: 'Ninguna' }
            }
            
            setAPIData(APIData => [...APIData, {
                id: playlist.id,
                Name: playlist.Name,
                CategName: Name.Name,
                CategoryId: playlist.CategoryId 
            }])
        })
    }

    const onDelete = (id) => {
        axios.delete(`https://mcqueeninc.net/api/playlist/${id}`, {headers: authHeader()})
            .then((response) => {
                getData();
            })
    }

    useEffect(() => {
        getData();
    }, [])

    return (

        <div>
            <div>
                <Link to={'/createPlaylist'}>
                    <Button positive>
                        Crear lista de repoducción
                    </Button>
                </Link>
            </div>
            <div>
                <br></br>
            </div>
            <MaterialTable
                title="Listados de Reproducción"
                columns={[
                    { title: 'Nombre', field: 'Name' },
                    { title: 'Categoría', field: 'CategName' },

                ]}
                data={APIData}
                actions={[

                    {
                        icon: 'edit',
                        tooltip: 'Editar lista de reproduccion',
                        onClick: (event, rowData) => setData(rowData)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Eliminar lista de reproduccion',
                        onClick: (event, rowData) => onDelete(rowData.id)
                    },
                    {
                        icon: 'add',
                        tooltip: 'Agregar canción',
                        onClick: (event, rowData) => setDataModal(rowData)
                    },
                    {
                        icon: 'sort',
                        tooltip: 'Ordenar',
                        onClick: (event, rowData) => setDataSortModal(rowData)
                    },
                    {
                        icon: () => <Button primary>Detalles</Button>,
                        tooltip: 'Ver detalles',
                        onClick: (event, rowData) => setDataModalList(rowData)
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    }
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}'
                    },
                    toolbar: {
                        nRowsSelected: '{0} row(s) selected'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No contenido para mostrar',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    },
                    pagination: {
                        labelRowsSelect: 'lineas'
                    },
                    toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                    }
                }}>

            </MaterialTable>
            {modalShow && <PickSong data={modalData} show={modalShow} onHide={() => setModalShow(false)} />}
            {modalShowList && <SongList data={modalData} show={modalShowList} onHide={() => setModalShowList(false)} />}
            {modalShowSort && <SortSongs data={modalData} show={modalShowSort} onHide={() => setModalShowSort(false)} />}
        </div>
    )
}