import React, { useEffect, useState } from 'react'
import axios from 'axios';
import MaterialTable from "material-table";
import AuthService from "../../services/auth.service";
import authHeader from '../../services/auth-header';
export default function AdsStatistics() {

    const [adsData, setadsData] = useState([]);
    const [Loaded, setLoaded] = useState(false);
    const [refreshInterval, setRefreshInterval] = useState(1000000);
    const [gridColumns, setGridColumns] = useState([]);

    const getData = async () => {

        const APIdata = await axios.get(`https://mcqueeninc.net/api/song?Ads=true`, { headers: authHeader()})
        
        const AdsDATA = await axios.get(`https://mcqueeninc.net/api/ads`, { headers: authHeader()})

        const UserData = await axios.get(`https://mcqueeninc.net/api/users`, { headers: authHeader()});

        const LogData = await axios.get(`https://mcqueeninc.net/api/logs`, { headers: authHeader()});

        const user = AuthService.getCurrentUser();

        const adminRole = user.roles.includes("ROLE_ADMIN");

        setadsData([]);
        if(adminRole){
            console.log('Editing...');
            setGridColumns([
                { title: 'Anuncio', field: 'title' },
                { title: 'Total', field: 'qtyPlays' },
                { title: 'Negocios', field: 'fullname' },
                { title: 'Total detallado', field: 'total' },
                {title: 'Propietario', field: 'owner'}]);

        }
        else{
            setGridColumns([
                { title: 'Anuncio', field: 'title' },
                { title: 'Total', field: 'qtyPlays' },
                { title: 'Negocios', field: 'fullname' },
                { title: 'Total detallado', field: 'total' },
        
            ]);
        }
        
        
        APIdata.data.filter(song => !adminRole ? song.UserId == user.id : song.isBlocked)
            .map(items => {

                const UserLogs = LogData.data.song.reduce(function (n, logs) {

                    return n + (logs.SongId == items.id)
                }, 0)
                
                const DetailLogs = LogData.data.song.filter(logs => logs.SongId && logs.SongId == items.id)
                    // .map(item => item.Username)
                    .reduce((prev, item) => {
                        if (!prev[item.Username]) {
                            prev[item.Username] = 1;
                        } else {
                            prev[item.Username] += 1;
                        }
                        return prev;
                    }, {});


                setadsData(userData => [...userData, {
                    id: items.id,
                    title: items.Title,
                    qtyPlays: UserLogs,
                    owner: UserData.data.find(user => user.id == items.UserId).fullname
                }]);

                Object.keys(DetailLogs).map(data => {

                    let userInfo = UserData.data.find(user => user.username == data);

                    setadsData(userData => [...userData, {
                        id: userInfo.id,
                        parentId: items.id,
                        fullname: userInfo.fullname,
                        total: DetailLogs[data]
                    }]);
                })
            });

            AdsDATA.data
            .filter(song => !adminRole ? song.UserId == user.id : song)
            .map(items => {

                const UserLogs = LogData.data.song.reduce(function (n, logs) {

                    return n + (logs.SongId == items.id)
                }, 0)
                
                const DetailLogs = LogData.data.song.filter(logs => logs.SongId && logs.SongId == items.id)
                    // .map(item => item.Username)
                    .reduce((prev, item) => {
                        if (!prev[item.Username]) {
                            prev[item.Username] = 1;
                        } else {
                            prev[item.Username] += 1;
                        }
                        return prev;
                    }, {});


                setadsData(userData => [...userData, {
                    id: items.id,
                    title: items.Title,
                    qtyPlays: UserLogs,
                    owner: UserData.data.find(user => user.id == items.UserId).fullname
                }]);

                Object.keys(DetailLogs).map(data => {

                    let userInfo = UserData.data.find(user => user.username == data);

                    setadsData(userData => [...userData, {
                        id: userInfo.id,
                        parentId: items.id,
                        fullname: userInfo.fullname,
                        total: DetailLogs[data]
                    }]);
                })
            });

            

        setLoaded(true);

    }

    useEffect(() => {
        getData();
    }, [])

    if (Loaded && gridColumns) {
        console.log(gridColumns);
        return (

            <div>
                <MaterialTable
                    title="Estadísticas de anuncios"
                    columns={gridColumns}
                    data={adsData}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                    options={{
                        // actionsColumnIndex: -1,
                        headerStyle: {
                            fontSize: '16px',
                            fontWeight: 'bold'
                        },
                        pageSize: 10
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay contenido para mostrar',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        },
                        pagination: {
                            labelRowsSelect: 'lineas'
                        },
                        toolbar: {
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        }
                    }}>

                </MaterialTable>
            </div>
        )
    }
    else {
        return (
            <div>
                Loading...
            </div>
        )
    }
}