import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import classes from '../Add/Category.module.css';
import Card from '../../UI/Card';
import authHeader from '../../../services/auth-header';

function UpdateCategory() {
    const history = useHistory();
    const [id, setID] = useState(null);
    const [Name, setName] = useState('');

    function submitHandler(event) {
        event.preventDefault();
        handleSubmission(); 
    }

    const handleSubmission = () => {

        var requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'x-access-token': authHeader() },
            body: JSON.stringify({
                Name: Name
            }),
            redirect: 'follow'
        };

        fetch(`https://mcqueeninc.net/api/category/${id}`, requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {
                
                console.log(result)
                history.goBack();
            })
            .catch(error => console.log('error', error));

    }

    useEffect(() => {
        setID(localStorage.getItem('ID'))
        setName(localStorage.getItem('Name'));
        
    }, []);

    return (
        <Card>
            <div>
                <form id='categoryForm' className={classes.form} onSubmit={submitHandler}>
                    <div className={classes.control}>
                        <label htmlFor='Name'>Nombre de la Categoría</label>
                        <input type='text' required id='name' value={Name} onChange={((e) => setName(e.target.value))} />
                    </div>

                    <div className={classes.actions}>
                        <button>Actualizar Categoría</button>
                    </div>
                </form>
            </div>
        </Card>
    )

}

export default UpdateCategory;