import React, { useEffect, useState } from 'react'
import { Table, Button } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import MaterialTable from "material-table";
import authHeader from '../../services/auth-header';
export default function Read(props) {
    const [Loaded, setLoaded] = useState(false);
    const [APIData, setAPIData] = useState([]);
    const history = useHistory();

    const getData = async () => {

        setAPIData([]);

        const ads = await axios.get(`https://mcqueeninc.net/api/song/?Ads=true`, { headers: authHeader() });
        const users = await axios.get(`https://mcqueeninc.net/api/users`, { headers: authHeader() });

        ads.data.filter(songs => songs.isBlocked).map(ad => {

            const username = users.data.find(user => user.id === ad.UserId);
            let data;
            if (username) {
                data = { ...ad, titular: username.fullname };
            } else {
                data = { ...ad };
            }

            setAPIData(APIData => [...APIData, data
            ])
        });
        setLoaded(true);

    }
    const setData = (data) => {
        ClearStorage();
        let { id, Title, playlistid, categories, ProvinciaId, MunicipioId, DistritoMunicipalId, UserId, Src, SongImg, UserSpecific, UserExcep, Intensity, IntensityByUser, IntensityByZone } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('Title', Title);
        localStorage.setItem('Src', Src);
        localStorage.setItem('SongImg', SongImg);
        localStorage.setItem('PlaylistId', playlistid);
        localStorage.setItem('Categories', categories);

        if (ProvinciaId)
            localStorage.setItem('ProvinciaId', JSON.parse(ProvinciaId));
        if (MunicipioId)
            localStorage.setItem('MunicipioId', JSON.parse(MunicipioId));
        if (DistritoMunicipalId)
            localStorage.setItem('SectorId', JSON.parse(DistritoMunicipalId));

        localStorage.setItem('UserId', UserId);
        localStorage.setItem('UserSpecific', UserSpecific);
        localStorage.setItem('UserExcep', UserExcep);
        localStorage.setItem('Intensity', Intensity);
        localStorage.setItem('IntensityByUser', JSON.parse(IntensityByUser));
        localStorage.setItem('IntensityByZone', JSON.parse(IntensityByZone));



        history.push('ads/update');
    }

    const ClearStorage = () => {
        // localStorage.removeItem();
        
        localStorage.removeItem('ID');
        localStorage.removeItem('Title');
        localStorage.removeItem('Src');
        localStorage.removeItem('SongImg');
        localStorage.removeItem('PlaylistId');
        localStorage.removeItem('Categories');
        localStorage.removeItem('ProvinciaId');
        localStorage.removeItem('MunicipioId');
        localStorage.removeItem('SectorId');
        localStorage.removeItem('UserId');
        localStorage.removeItem('UserSpecific');
        localStorage.removeItem('UserExcep');
        localStorage.removeItem('Intensity');
        localStorage.removeItem('IntensityByUser');
        localStorage.removeItem('IntensityByZone');
    }

    const onDelete = (id) => {
        axios.delete(`https://mcqueeninc.net/api/song/${id}`, { headers: authHeader() })
            .then((response) => {
                console.log(response.data);
                getData();
            })
    }

    useEffect(() => {
        getData();
    }, [])
    if (Loaded)
        return (

            <div>
                <div>
                    <Link to={'/ads/create'}>
                        <Button primary>
                            Subir anuncio
                        </Button>
                    </Link>
                </div>
                <div>
                    <br></br>
                </div>
                <div>
                    <MaterialTable
                        title="Lista de Anuncios"
                        columns={[
                            { title: 'Titulo', field: 'Title' },
                            { title: 'Propietario', field: 'titular' },
                            // { title: 'Duración', field: 'Duration' },

                        ]}
                        data={APIData.filter(song => song.isBlocked == 1)}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Editar anuncio',
                                onClick: (event, rowData) => setData(rowData)
                            },
                            {
                                icon: 'delete',
                                tooltip: 'eliminar anuncio',
                                onClick: (event, rowData) => onDelete(rowData.id)
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            headerStyle: {
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}'
                            },
                            toolbar: {
                                nRowsSelected: '{0} row(s) selected'
                            },
                            header: {
                                actions: 'Acciones'
                            },

                            pagination: {
                                labelRowsSelect: 'lineas'
                            },
                            toolbar: {
                                searchTooltip: 'Buscar',
                                searchPlaceholder: 'Buscar'
                            },
                            body: {
                                emptyDataSourceMessage: 'No contenido para mostrar',
                                filterRow: {
                                    filterTooltip: 'Filter'
                                }
                            }
                        }}>

                    </MaterialTable>
                </div>

            </div>
        )

    return <div>Loading...</div>
}