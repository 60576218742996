import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import authHeader from '../../services/auth-header';
import Toast from 'react-bootstrap/Toast'
import { styled } from '@mui/material/styles';
import {
    TextField,
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Button,
    InputLabel,
    Select
}
    from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Municipios from '../../JsonFiles/municipios.json'
import Provincias from '../../JsonFiles/provincias.json'
import Sectores from '../../JsonFiles/sectores.json'
import SaveIcon from '@mui/icons-material/Save';
import { Title } from '@mui/icons-material';
import EventBus from '../../common/EventBus';
import IntensityAdsForm from '../IntensityAdsForm/IntensityAdsForm';
import IntensityAdsZone from '../IntensityAdsZone/IntensityAdsZone';

import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function CreatePlaylist() {
    const history = useHistory();

    const [RoleApi, setAPIData] = useState([]);
    const [Categories, SetAPICategories] = useState([]);

    const [fieldProvincias, setProvincias] = useState([]);
    const [fieldMunicipios, setMunicipios] = useState([]);

    const [Users, setUsers] = useState([]);

    const [valueProvincias, setValProvincias] = useState('');
    const [valueMunicipios, setValMunicipios] = useState('');
    const [valueSectores, setValSectores] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileMp3, setSelectedFileMp3] = useState();
    const [category, setCategory] = useState(0);
    const nameInputRef = useRef();
    const urlYoutubeRef = useRef();
    const [userVal, setUserVal] = useState('');
    const [userExcep, setUserExcep] = useState(0);
    const [userSpecific, setUserSpecific] = useState(0);
    const [checkIntensityUser, setCheckIntensityUser] = useState(false);
    const [checkIntensityZone, setCheckIntensityZone] = useState(false);
    const [intensity, setIntensity] = useState(50);
    const [intensityByUser, setIntensityByUser] = useState([]);
    const [intensityByZone, setIntensityByZone] = useState([]);

    const [defaultTypes]
        = useState([{ label: "Video", id: "video" }, { label: "Banner", id: "banner" }]);

    const [selectedType, setSelectedType] = useState([]);

    const Input = styled('input')({
        display: 'none',
    });

    function submitHandler(event) {
        event.preventDefault();

        if (validateSubmission()) {
            handleSubmission();
        }
        // 
    }
    const changeHandlerImg = (event) => {

        setSelectedFile(event.target.files[0]);

    };
    const changeHandlerMp3 = (event) => {

        setSelectedFileMp3(event.target.files[0]);

    };

    const handleChange = (event) => {
        setCheckIntensityUser(event.target.checked);
    };

    const handleChangeZone = (event) => {
        setCheckIntensityZone(event.target.checked);
    };

    const getUserIntensity = (IntensityInfo) => {
        setIntensityByUser(IntensityInfo);
    }

    const getZoneIntensity = (IntensityInfo) => {
        setIntensityByZone(IntensityInfo);
    }

    const getPlaylistData = () => {
        axios.get(`https://mcqueeninc.net/api/playlist`, { headers: authHeader() })
            .then((response) => {

                response.data.map(playlist => {
                    setAPIData(RoleApi => [...RoleApi, {
                        label: playlist.Name,
                        id: playlist.id
                    }]);
                })
            })
            .catch(function (error) {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            })
            ;

    }

    const getUsers = () => {
        axios.get(`https://mcqueeninc.net/api/users`, { headers: authHeader() })
            .then((response) => {

                response.data.map(user => {
                    setUsers(Users => [...Users, {
                        label: user.fullname,
                        id: user.id
                    }]);

                })
            })
            .catch(function (error) {

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            });
    }
    const validateSubmission = () => {
        if (nameInputRef.current.value.trim() === '' || userVal.toString().trim() === '' || selectedType.length > 0 === '') {
            alert('Por favor completar campos obligatorios');
            return false;
        }
        else {
            return true;
        }
    }
    const handleSubmission = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic RGV2VXNlcjpEZXZlbG9wZXJzMTIzJA==");

        var formdata = new FormData();
        formdata.append("Title", nameInputRef.current.value);

        if (selectedFileMp3)
            formdata.append("Src", selectedFileMp3, selectedFileMp3 ? selectedFileMp3.name : '');
        formdata.append("YoutubeUrl", urlYoutubeRef.current.value);
        formdata.append("AdType", "video");
        // formdata.append("categories", (category.length > 0 ) ? category : null);

        // formdata.append("ProvinciaId", valueProvincias);
        // formdata.append("MunicipioId", valueMunicipios);
        // formdata.append("DistritoMunicipalId", valueSectores);

        if (!userSpecific || userSpecific == '') {

            formdata.append("categories", (category.length > 0) ? JSON.stringify(category) : []);
            formdata.append("UserExcep", (userExcep.length > 0) ? userExcep : []);

            console.log(valueProvincias);
            if (Array.isArray(valueProvincias) && valueProvincias.length > 0)
                formdata.append("ProvinciaId", JSON.stringify(valueProvincias));

            if (Array.isArray(valueMunicipios) && valueMunicipios.length > 0)
                formdata.append("MunicipioId", JSON.stringify(valueMunicipios));

            if (Array.isArray(valueSectores) && valueSectores.length > 0)
                formdata.append("DistritoMunicipalId", JSON.stringify(valueSectores));

        }
        if (userSpecific && userSpecific != '' && userSpecific.length > 0)
            formdata.append("UserSpecific", userSpecific);

        if (intensityByUser.length > 0)
            formdata.append('IntensityByUser', JSON.stringify(intensityByUser));

        if (intensityByZone.length > 0)
            formdata.append('IntensityByZone', JSON.stringify(intensityByZone));


        formdata.append("UserId", userVal);

        var requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://mcqueeninc.net/api/ads", requestOptions)
            .then(response => {
                response.text()
                console.log(response);
                history.goBack();
                // document.getElementById('songForm').reset();
            })
            .then(result => console.log(result))
            .catch(function (error) {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            });

    }

    const getCategoryData = () => {
        axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader() })
            .then((response) => {
                response.data.map(categ => {
                    SetAPICategories(Categorias => [...Categorias, {
                        label: categ.Name,
                        id: categ.id
                    }]);
                })
            })
            .catch(function (error) {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            });
    }

    const setProvinciasValue = (value) => {
        setValProvincias(value);
    }

    const getProvincias = () => {

        Provincias.map(prov => {
            setProvincias(fieldProvincias => [...fieldProvincias, {
                label: prov.provincia,
                id: prov.provincia_id
            }]);
        });
    }

    useEffect(() => {
        getPlaylistData();
        getCategoryData();
        getProvincias();
        getUsers();

    }, [])
    if (fieldMunicipios && Users.length > 1) {

        return (
            <div>
                <form
                    autoComplete="off"
                    noValidate

                >
                    <Card>
                        <CardHeader
                            subheader="Agregando un nuevo anuncio"
                            title="Anuncios"
                        />
                        <Divider />
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                            >

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField required
                                        fullWidth
                                        id="outlined-basic"
                                        label="Titulo"
                                        inputRef={nameInputRef}
                                        variant="outlined" />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Url de Youtube (Opcional)"
                                        inputRef={urlYoutubeRef}
                                        variant="outlined" />
                                </Grid>
                                {/* <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        required
                                        disablePortal
                                        id="type"
                                        options={defaultTypes}
                                        sx={{ width: 300 }}
                                        // getOptionLabel={(Users) => Users.label ? Users.label : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setSelectedType(value.id) : setSelectedType('')}
                                        renderInput={(params) => <TextField {...params} label="Tipo" />}
                                    />

                                </Grid> */}
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        required
                                        disablePortal
                                        id="users"
                                        options={Users}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Users) => Users.label ? Users.label : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setUserVal(value.id) : setUserVal('')}
                                        renderInput={(params) => <TextField {...params} label="Propietario" />}
                                    />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Intensidad General</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="weight"
                                            defaultValue={50}
                                            // value={input.weight ? input.weight : 0}
                                            label="Intensidad"
                                            onChange={(event) => setIntensity(event.target.value)}
                                        >
                                            <MenuItem value={0}>
                                                <em>Ninguna</em>
                                            </MenuItem>
                                            <MenuItem value={25}>Baja</MenuItem>
                                            <MenuItem value={50}>Media</MenuItem>
                                            <MenuItem value={75}>Alta</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="category"
                                        options={Categories}
                                        sx={{ width: 300 }}
                                        getOptionLabel={Categories => Categories.label}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setCategory(value.map(item => item.id))}
                                        renderInput={(params) => <TextField {...params} label="Categoria" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="userSpecific"
                                        options={Users}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Users) => Users.label ? Users.label : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setUserSpecific(value.map(item => item.id))}
                                        renderInput={(params) => <TextField {...params} label="Negocios especificos" />}
                                    />
                                </Grid>
                                {(!userSpecific || userSpecific == '') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="userExcep"
                                        options={Users}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Users) => Users.label ? Users.label : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setUserExcep(value.map(item => item.id))}
                                        renderInput={(params) => <TextField {...params} label="Negocios excluidos" />}
                                    />
                                </Grid>}
                                {(!userSpecific || userSpecific == '') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="provincias"
                                        options={fieldProvincias}
                                        sx={{ width: 300 }}
                                        getOptionLabel={fieldProvincias => fieldProvincias.label}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setProvinciasValue(value.map(item => item.id)) : setProvinciasValue('')}
                                        renderInput={(params) => <TextField {...params} label="Provincia" />}
                                    />
                                </Grid>}
                                {(!userSpecific || userSpecific == '') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="municipios"
                                        options={Municipios.filter(item => {
                                            if (valueProvincias) {
                                                return valueProvincias.includes(item.provincia_id);
                                            } else {
                                                return '';
                                            }
                                        })}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Municipios) => Municipios.minicipio ? Municipios.minicipio : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setValMunicipios(value.map(item => item.minicipio_id)) : setValMunicipios('')}
                                        renderInput={(params) => <TextField {...params} label="Municipio" />}
                                    />
                                </Grid>}

                                {(!userSpecific || userSpecific == '') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="sectores"

                                        options={Sectores.filter(item => {
                                            if (valueMunicipios) {
                                                return valueMunicipios.includes(item.municipio_id);
                                            } else {
                                                return '';
                                            }
                                        })}
                                        renderOption={(props, option) => (

                                            <li {...props} key={option.sector_id}>
                                                {option.sector}
                                            </li>
                                        )}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Sectores) => Sectores.sector ? Sectores.sector : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setValSectores(value.map(item => item.sector_id)) : setValSectores('')}
                                        renderInput={(params) => <TextField {...params} label="Sector" />}
                                    />
                                </Grid>}
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <div>
                                        <Input type='file' onChange={changeHandlerMp3} id='upload-mp3' />
                                        <label htmlFor='upload-mp3'>

                                            <Button variant="contained" component="span">
                                                Subir multimedia
                                            </Button>
                                        </label>
                                    </div>
                                </Grid>
                                {/* <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <div>
                                        <Input type='file' onChange={changeHandlerImg} id='upload-img' />
                                        <label htmlFor='upload-img'>

                                            <Button variant="contained" component="span">
                                                Subir portada
                                            </Button>
                                        </label>
                                    </div>
                                </Grid> */}
                            </Grid>

                        </CardContent>
                        <Divider />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checkIntensityZone}
                                        onChange={handleChangeZone}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                label="Intensidad por Zona" />
                            {checkIntensityZone &&
                                <IntensityAdsZone
                                    Provincias={valueProvincias ? fieldProvincias.filter(item => item.id === valueProvincias) : fieldProvincias}
                                    Municipios={valueMunicipios ? Municipios.filter((item => item.minicipio_id === valueMunicipios)) : Municipios}
                                    Sectores={valueSectores ? Sectores.filter(item => item.sector_id === valueSectores) : Sectores}
                                    handleIntensityInfo={getZoneIntensity}>
                                </IntensityAdsZone>
                            }

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checkIntensityUser}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                label="Intensidad por Usuario" />
                            {checkIntensityUser && <IntensityAdsForm Users={Users} handleIntensityInfo={getUserIntensity}></IntensityAdsForm>}
                        </FormGroup>


                        <Divider />
                        <Box

                            id='userForm'
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '45ch' },
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                            // noValidate
                            autoComplete="off"
                            onSubmit={submitHandler}

                        >
                            <div>

                                <Button type='submit' variant="contained" startIcon={<SaveIcon />}>Agregar anuncio</Button>
                            </div>

                        </Box>
                    </Card>
                </form>
            </div>
        )
    }
    else {

        return <div>Loading...</div>
    }
}

export default CreatePlaylist;