import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://mcqueeninc.net/api/';

class LogService {

    LogEvent(type, username, details, songId, isAds) {
        console.log(username);
        axios.post(API_URL + 'logs', {
            
            Type: type,
            Username: username,
            Details: details,
            SongId: songId,
            IsAds: isAds
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            console.log(response);
            
            return response.status;
        }, (error) => {
            return error
        })

    }
}

export default new LogService;