import ImageGallery from 'react-image-gallery';
import LogService from "../../services/log.service"
import React, { useState, useEffect } from 'react';
import AuthService from "../../services/auth.service";
import ReactJkMusicPlayer from "react-jinke-music-player"
import 'react-jinke-music-player/assets/index.css'
import SongAutocomplete from '../SongAutocomplete/SongAutocomplete';
class MusicPlayer extends React.PureComponent {

  constructor(props) {
    super(props)
    this.audio = {}
    this.user = AuthService.getCurrentUser();
  }

  state = {
    image: [{
      original: "https://mcqueeninc.net/images/Background-image.jpg",
      thumbnail: "https://mcqueeninc.net/images/Background-image.jpg"
    }],
    songPlaying: 0,
    playingAds: 0,
    unmount: false,
    inputOpen: false,
    inputValue: "",
    adCount: 2,
    counter: 0,
    activeDrags: 0,
    pickedSong: '',
    musics: [...new Set(this.props.allSongs)],
    params: {

      audioLists: this.props.audioList,

      // default play index of the audio player  [type `number` default `0`]
      defaultPlayIndex: 0,

      // if you want dynamic change current play audio you can change it [type `number` default `0`]
      // playIndex: 0,

      // color of the music player theme    [ type: 'light' | 'dark' | 'auto'  default `dark` ]
      theme: 'dark',

      // Specifies movement boundaries. Accepted values:
      // - `parent` restricts movement within the node's offsetParent
      //    (nearest node with position relative or absolute), or
      // - a selector, restricts movement within the targeted node
      // - An object with `left, top, right, and bottom` properties.
      //   These indicate how far in each direction the draggable
      //   can be moved.
      // Ref: https://github.com/STRML/react-draggable#draggable-api
      bounds: 'body',

      /**
       * Don't interrupt current playing state when audio list updated
       * audioLists eg. (A) is current playing...
       * [A,B] => [A,C,B]
       * [A,B] => [A,B,C]
       *
       * if (A) not in updated audio lists
       * [A,B] => [C]
       * (C) is playing
       */
      // [type `boolean`, default `false`]
      quietUpdate: false,

      // Replace a new playlist with the first loaded playlist
      // instead of adding it at the end of it.
      // [type `boolean`, default `false`]
      clearPriorAudioLists: false,

      // Play your new play list right after your new play list is loaded turn false.
      // [type `boolean`, default `false`]
      autoPlayInitLoadPlayList: true,

      // Whether to load audio immediately after the page loads.  [type `Boolean | String`, default `false`]
      // "auto|metadata|none" "true| false"
      preload: true,

      // Whether the player's background displays frosted glass effect  [type `Boolean`, default `false`]
      glassBg: false,

      // The next time you access the player, do you keep the last state  [type `Boolean` default `false`]
      remember: false,

      // The Audio Can be deleted  [type `Boolean`, default `true`]
      remove: this.props.remove,

      // audio controller initial position    [ type `Object` default '{top:0,left:0}' ]
      defaultPosition: {
        right: 100,
        bottom: 120,
      },

      // if you want dynamic change current play mode you can change it
      // [type`order | orderLoop | singleLoop | shufflePlay`, default `order`]
      // playMode: 'order',
      defaultPlayMode: 'orderLoop',

      // audio mode        mini | full          [type `String`  default `mini`]
      mode: 'full',

      showPanel: false,

      /**
       * [ type `Boolean` default 'false' ]
       * The default audioPlay handle function will be played again after each pause, If you only want to trigger it once, you can set 'true'
       */
      once: false,

      // Whether the audio is played after loading is completed. [type `Boolean` default 'true']
      autoPlay: true,

      // Whether you can switch between two modes, full => mini  or mini => full   [type 'Boolean' default 'true']
      toggleMode: true,

      // audio cover is show of the "mini" mode [type `Boolean` default 'true']
      showMiniModeCover: true,

      // audio playing progress is show of the "mini"  mode
      showMiniProcessBar: false,

      // audio controller is can be drag of the "mini" mode     [type `Boolean` default `true`]
      drag: false,

      // drag the audio progress bar [type `Boolean` default `true`]
      seeked: this.props.seeked,

      // Display chrome media session.  [type `Boolean` default `false`]
      showMediaSession: true,

      // Displays the audio load progress bar.  [type `Boolean` default `true`]
      showProgressLoadBar: true,

      // play button display of the audio player panel   [type `Boolean` default `true`]

      showPlay: true,

      // reload button display of the audio player panel   [type `Boolean` default `true`]
      showReload: true,

      // download button display of the audio player panel   [type `Boolean` default `true`]
      showDownload: false,

      // loop button display of the audio player panel   [type `Boolean` default `true`]
      showPlayMode: false,

      // theme toggle switch  display of the audio player panel   [type `Boolean` default `true`]
      showThemeSwitch: true,

      // lyric display of the audio player panel   [type `Boolean` default `false`]
      showLyric: false,

      // destroy player button display  [type `Boolean` default `false`]
      showDestroy: this.props.destroy,

      // Extensible custom content       [type 'Array' default '-' ]
      extendsContent: ''
      // <div>
      //   <Autocomplete
      //     id="Songs"
      //     sx={{
      //       display: 'inline-block',
      //       // color: 'white',
      //       width: 200,
      //       '& input': {
      //         width: 200,
      //         // bgcolor: 'background.paper',
      //         fontStyle: { color: 'white' },

      //         color: 'white'
      //       }
      //     }}
      //     // openOnFocus={this.state.inputOpen ? this.state.inputOpen : false}
      //     options={this.props.allSongs}
      //     renderOption={(props, option) => {
      //       return (
      //         <li {...props} key={option.id}>
      //           {option.name}
      //         </li>
      //       );
      //     }}
      //     getOptionLabel={(Songs) => Songs.name ? Songs.name : ''}
      //     //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
      //     onChange={(event, value) => value ? this.onAddAudio(value) : this.setState({ pickedSong: '' })}
      //     renderInput={(params) => <TextField {...params} label="Busca tu canción" />}
      //   />
      // </div>
      ,

      // default volume of the audio player [type `Number` default `1` range `0-1`]
      defaultVolume: 1,

      // playModeText show time [type `Number(ms)` default `600`]
      playModeShowTime: 600,

      // Whether to try playing the next audio when the current audio playback fails [type `Boolean` default `true`]
      loadAudioErrorPlayNext: true,

      // Auto hide the cover photo if no cover photo is available [type `Boolean` default `false`]
      autoHiddenCover: false,

      // Play and pause audio through blank space [type `Boolean` default `false`]
      spaceBar: false,

      // international [type `en_US | zh_CN | Object` default `en_US`]
      locale: "en_US",

      // Enable responsive player, auto toggle desktop and mobile [type `Boolean` default `true`]
      responsive: true,

      /**
       * Custom mobile media query string, eg use the mobile version UI on iPad.
       * https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries
       * [type `String` default '(max-width: 768px) and (orientation : portrait)']
       */
      mobileMediaQuery: '(max-width: 1024px)',

      // Audio volume with fade in and fade out [type `{ fadeIn: number, fadeOut: number }` default `{ fadeIn: 0, fadeOut: 0 }`]
      volumeFade: {
        fadeIn: 0,
        fadeOut: 0,
      },
      /**
       * Restarts the current track when trying to play previous song, if the current time of the song is more than 1 second
          Otherwise, plays the previous song in the list
          [type `Boolean` default `false`]
      */
      restartCurrentOnPrev: false,

      // https://github.com/SortableJS/Sortable#options
      sortableOptions: {},
      // When the user has moved/jumped to a new location in audio
      onAudioSeeked(audioInfo) {
        console.log('audio seeked', audioInfo);
        let user = AuthService.getCurrentUser();
        LogService.LogEvent("PLAYSEEKED", user.username, `User ${user.username} change seek this song ${audioInfo.name}`);
      },

      // When the volume has changed  min = 0.0  max = 1.0
      onAudioVolumeChange(currentVolume) {
        console.log('audio volume change', currentVolume);
        let user = AuthService.getCurrentUser();
        LogService.LogEvent("VOLCHANGE", user.username, `User ${user.username} set the volume ${currentVolume * 100}%`);
      },



      // custom music player root node
      getContainer() {
        return document.body
      },

      onBeforeDestroy(currentPlayId, audioLists, audioInfo) {
        return new Promise((resolve, reject) => {
          // your custom validate
          // eslint-disable-next-line no-alert
          if (window.confirm('Esta seguro que desea cerrar el reproductor?')) {
            // if resolve, player destroyed
            resolve()
          } else {
            // if reject, skip.
            reject()
          }
        })
      },
      getAudioInstance: (audio) => {
        this.audio = audio
      },

    },
  }

  updateImage = (image) => {
    this.setState({
      image: [{
        original: image,
        thumbnail: image
      }]
    });
  }

  updateParams = (p_params) => {
    const data = {
      ...this.state.params,
      ...p_params,
    }

    this.setState({
      params: data,
    })

  }
  onAudioPlay = (audioInfo) => {

    console.log(this);
    let isAds = 0;
    let currentSong = this.props.originalList.find(song => song.Title == audioInfo.name);
    console.log(this.props.originalList);
    var song = this.props.AdsList.find(song => song.Title === audioInfo.name);
    if (song && song.isBlocked && !this.props.admin) {
      this.updateParams({
        seeked: false
      })
    }
    else {
      this.updateParams({
        seeked: true
      })
    }

    this.setState({
      songPlaying: currentSong,
      playingAds: isAds
    });

    if (song && !this.props.admin) {
      isAds = 1;
      LogService.LogEvent("PLAYON", this.props.user.username, `User ${this.user.username} is playing ${audioInfo.name}`, song.id, isAds);
    } else {
      LogService.LogEvent("PLAYON", this.props.user.username, `User ${this.user.username} is playing ${audioInfo.name}`, currentSong.SongId, isAds);
    }

  }

  onAudioPause = (audioInfo) => {
    let user = AuthService.getCurrentUser();
    LogService.LogEvent("PLAYSTOP", user.username, `User ${user.username} is stopping ${audioInfo.name, this.songPlaying, this.playingAds}`);
  }

  onAudioEnded = () => {
    console.log(this.state.pickedSong);
    if (this.state.pickedSong != '') {
      this.setState({ pickedSong: '' });
      this.updateParams({
        clearPriorAudioLists: true,
        quietUpdate: false,
        audioLists: this.props.audioList,
      });
    }
  }

  onAddExtension = () => {
    this.updateParams({
      extendsContent: (
        <div>
          <SongAutocomplete 
          allSongs={this.props.allSongs}
          onAddAudio={this.onAddAudio}>

          </SongAutocomplete>
        </div>
      )
    })
  }

  componentDidMount = () => {
    console.log('Component mount');
    this.onAddExtension();
  }


  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  onAfterEnded = () => {
    console.log("Test after ended");
  }

  onAddAudio = (audio) => {
    this.setState({ pickedSong: audio });
    this.updateParams({
      clearPriorAudioLists: true,
      quietUpdate: false,
      audioLists: [
        // ...this.state.params.audioLists,
        {
          name: audio.name,
          singer: audio.singer,
          cover: audio.cover,
          musicSrc: audio.musicSrc,
        },
      ],
      audioLists2: this.props.audioList
    });
    // this.setState({ pickedSong: '' });
  }

  render = () => {
    return (

      <div className="MusicPlayer">

        {this.state.image && <ImageGallery items={this.state.image}></ImageGallery>}
        <ReactJkMusicPlayer
          onAudioPlay={this.onAudioPlay}
          onAudioPause={this.onAudioPause}
          onAudioEnded={this.onAudioEnded}
          {...this.state.params}>
        </ReactJkMusicPlayer>

      </div>
    );
  }
}

export default MusicPlayer;
