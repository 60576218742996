import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Table, Button } from 'semantic-ui-react';
// import Button from 'react-bootstrap/Button'
import axios from 'axios';
import './Style.css'
import authHeader from '../../services/auth-header';
import { getAudioDurationInSeconds } from '@remotion/media-utils';
import Util from '../../services/Util';

export default function PickSong(props) {

    const [APIData, setAPIData] = useState([]);
    const [APIPlaylistData, setPlaylistAPIData] = useState([]);
    const [searchTerm, setSearchText] = useState('');
    const [duration, setDuration] = useState(0);

    const getSongData = async () => {
        axios.get(`https://mcqueeninc.net/api/song`, { headers: authHeader() })
            .then((response) => {
                setAPIData(response.data);
                // Util.updateSongsDuration(response.data)
            })
    }

    const getPlaylistSongData = async (id) => {

        const playlistSongs = await axios.get(`https://mcqueeninc.net/api/playlist/getSongs/${id}`, { headers: authHeader() });

        setPlaylistAPIData(playlistSongs.data);

        
        var duration = playlistSongs.data.reduce((prev, curr) => prev + curr.Duration, 0);

        setDuration(Math.round(duration / 60));

    }

    const addSongToPlaylist = (data) => {

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': authHeader()['x-access-token'] },
            body: JSON.stringify({
                SongId: data,
                PlaylistId: props.data.id
            }),
            redirect: 'follow'
        };

        fetch("https://mcqueeninc.net/api/playlist/addSong", requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {

                getPlaylistSongData(props.data.id);
            })
            .catch(error => console.log('error', error));
    }

    const deleteSongToPlaylist = (data) => {

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': authHeader()['x-access-token'] },
            body: JSON.stringify({
                SongId: data,
                PlaylistId: props.data.id
            }),
            redirect: 'follow'
        };

        fetch("https://mcqueeninc.net/api/playlist/deleteSong", requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {

                getPlaylistSongData(props.data.id);
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if (props.show) {
            getSongData();
            getPlaylistSongData(props.data.id);
        }
    }, [props, props.data.id, props.data.show]);

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size='lg'>
            <Modal.Header closeButton>

                <Modal.Title id="contained-modal-title-vcenter">
                    Agregar canciones a la lista "{props.data.Name}"
                </Modal.Title>
            </Modal.Header>
            <Modal.Header>
                <Modal.Title>
                    Duración del Playlist {duration} minutos.
                </Modal.Title>
            </Modal.Header>
            <div class='search'>
                <input aria-invalid="false" placeholder="Buscar" type="text" aria-label="Search" className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd"
                    onChange={((e) => setSearchText(e.target.value))} />
            </div>
            <Modal.Body className="show-grid">
                <Table class="ui fixed table">
                    <Table.Header>
                        <Table.Row>
                            {/* <Table.HeaderCell>ID</Table.HeaderCell> */}
                            <Table.HeaderCell>Canción</Table.HeaderCell>
                            <Table.HeaderCell>Artista</Table.HeaderCell>
                            <Table.HeaderCell>Acción</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            APIData.filter((val) => {
                                if (searchTerm == "") {
                                    return val;
                                } else if (val.Title.toLowerCase().includes(searchTerm.toString().toLowerCase()) ||
                                    val.Artist.toLowerCase().includes(searchTerm.toString().toLowerCase())) {
                                    return val;
                                }
                            })
                                .map((data) => {
                                    let button;

                                    if (APIPlaylistData.find(song => song.SongId === data.id)) {

                                        button = <Button negative onClick={(() => deleteSongToPlaylist(data.id))}>Eliminar</Button>
                                    } else {
                                        button = <Button primary onClick={(() => addSongToPlaylist(data.id))}>Agregar canción </Button>
                                    }

                                    return (<Table.Row>
                                        {/* <Table.Cell>{data.id}</Table.Cell> */}
                                        <Table.Cell>{data.Title}</Table.Cell>
                                        <Table.Cell>{data.Artist}</Table.Cell>
                                        <Table.Cell>{button}</Table.Cell>
                                    </Table.Row>)
                                })
                        }
                    </Table.Body>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
} 