import React, { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import classes from './Category.module.css';
import Card from '../../UI/Card';
import authHeader from '../../../services/auth-header';
function CreateCategory() {

    const nameInputRef = useRef();
    const history = useHistory();

    function submitHandler(event) {
        event.preventDefault();
        handleSubmission();
    }
 
    const handleSubmission = () => {

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token':  authHeader()['x-access-token']},
            body: JSON.stringify({
                Name: nameInputRef.current.value
            }),
            redirect: 'follow'
        };

        fetch("https://mcqueeninc.net/api/category", requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {
                document.getElementById('categoryForm').reset();
                // console.log(result)
                history.goBack();
            })
            .catch(error => console.log('error', error));

    }

    return (
        <Card>
            <div>
                <form id='categoryForm' className={classes.form} onSubmit={submitHandler}>
                    <div className={classes.control}>
                        <label htmlFor='Name'>Nombre de la categoria</label>
                        <input type='text' required id='name' ref={nameInputRef} />
                    </div>

                    <div className={classes.actions}>
                        <button>Agregar Categoria</button>
                    </div>
                </form>
            </div>
        </Card>
    )

}

export default CreateCategory;