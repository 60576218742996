import axios from 'axios';
import authHeader from '../services/auth-header';
import AuthService from "../services/auth.service";
import EventBus from '../common/EventBus';
const API_URL = 'https://mcqueeninc.net/api/ads';

class AdsService {

    getAds() {

        return axios.get(API_URL, { headers: authHeader() })
            .catch(function (error) {
                console.log(error);
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    EventBus.dispatch("logout");
                }
            });

    }

    async buildAds() {
        let adsRaw = await axios.get(API_URL, { headers: authHeader() })
            .catch(function (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    EventBus.dispatch("logout");
                }
            });


        let ads = await this.filterAds(adsRaw);


        let images = [];
        if (ads) {

            ads.map(ad => {
                console.log(ad);
                if (ad.AdType == 'video') {
                    images.push({
                        type: 'Video',
                        id: ad.id,
                        embedUrl: ad.YoutubeUrl ? ad.YoutubeUrl : ad.Src
                    })
                }
                else if (ad.Src) {
                    images.push({
                        type: 'banner',
                        thumbnail: ad.Src,
                        original: ad.Src,
                    })
                }

            });

            return images;
        }
        else {
            return null;
        }
    }

    async getSubRandomAd() {
        let ads = await this.buildAds();

        let random = this.getRandomAd(ads);

        return random;
    }

    async filterAds(ads) {

        if (ads) {

            let user = AuthService.getCurrentUser();

            let AdsSpecific = { data: [] };
            AdsSpecific = ads.data.filter((ads) => ads.UserSpecific && ads.UserSpecific.includes(user.id));

            if (!AdsSpecific.data)
                AdsSpecific.data = [];

            let generalAds = ads.data.filter(ads => ads.categories == '[]' && ads.UserSpecific == '[]'
                && ads.ProvinciaId == '[]' && ads.MunicipioId == '[]' && ads.DistritoMunicipalId == '[]');

            const AdsByCategory = ads.data.filter(ads => {

                if (ads.categories && JSON.parse(ads.categories).includes(user.categoryId)) {

                    return ads;
                }
            });

            const AdsByCategoryOnly = ads.data.filter(ads => {
                if (ads.categories
                    && ads.categories.includes(user.categoryId)
                    && !ads.ProvinciaId && !ads.MunicipioId && !ads.DistritoMunicipalId) {
                    return ads;
                }
            });

            let AdsByProvincia = AdsByCategory.filter(ads => ads.ProvinciaId && ads.ProvinciaId == user.ProvinciaId && !ads.MunicipioId && !ads.DistritoMunicipalId);

            let AdsByMunicipio = AdsByCategory.filter(ads => ads.MunicipioId && ads.MunicipioId == user.MunicipioId && !ads.DistritoMunicipalId);

            let AdsBySector = AdsByCategory.filter(ads => ads.DistritoMunicipalId && ads.DistritoMunicipalId == user.DistritoMunicipalId);

            let AdsByProvinciaNotCat = ads.data.filter(ads => ads.ProvinciaId && ads.ProvinciaId == user.ProvinciaId
                && !ads.MunicipioId && !ads.DistritoMunicipalId);

            let AdsByMunicipioNotCat = ads.data.filter(ads => ads.MunicipioId && ads.MunicipioId == user.MunicipioId && !ads.DistritoMunicipalId);

            let AdsBySectorNotCat = ads.data.filter(ads => ads.DistritoMunicipalId && ads.DistritoMunicipalId == user.DistritoMunicipalId);

            let concatAds = AdsByMunicipio.concat(AdsByProvinciaNotCat, AdsByMunicipioNotCat,
                AdsBySectorNotCat, AdsByProvincia, AdsByMunicipio, AdsBySector, AdsByCategoryOnly, generalAds, AdsSpecific);

            let AllAds = [...new Set(concatAds)].filter(ads => !ads.UserExcep || !ads.UserExcep.includes(user.id));;

            return AllAds;

        }
    }

    getRandomAd(ads) {

        if (!ads || ads.length <= 0) return null;

        let user = AuthService.getCurrentUser();

        let weigths = ads.map(ad => {

            // console.log(ad);
            if (ad.IntensityByUser && ad.IntensityByUser != '[]') {
                let ArrayByUser = JSON.parse(JSON.parse(ad.IntensityByUser));
                let userIntensity = ArrayByUser.find((userAd) => userAd.userId == user.id)
                if (userIntensity)
                    return userIntensity.weight;
            }
            if (ad.IntensityByZone && ad.IntensityByZone != '[]') {
                let ArrayByZone = JSON.parse(JSON.parse(ad.IntensityByZone));
                console.log(ArrayByZone);

                let ZonaCompleta = ArrayByZone.find((zoneAd) => zoneAd.provinciaId == user.ProvinciaId &&
                    zoneAd.municipioId == user.MunicipioId &&
                    zoneAd.sectorId == user.DistritoMunicipalId)
                console.log(ZonaCompleta);
                if (ZonaCompleta)
                    return ZonaCompleta.weight;

                let zonaMunicipio = ArrayByZone.find((zoneAd) => zoneAd.provinciaId == user.ProvinciaId &&
                    zoneAd.municipioId == user.MunicipioId &&
                    !zoneAd.sectorId)
                if (zonaMunicipio)
                    return zonaMunicipio.weight;

                let zonaProvincia = ArrayByZone.find((zoneAd) => zoneAd.provinciaId == user.ProvinciaId &&
                    !zoneAd.municipioId &&
                    !zoneAd.sectorId)
                if (zonaProvincia)
                    return zonaProvincia.weight;

            }
            if (!ad.Intensity)
                ad.Intensity = 50;

            return ad.Intensity;

        });

        console.log(weigths);
        let randomAd = this.weigthedRandomAds(ads, weigths);
        return randomAd.item;
    }

    weigthedRandomAds(items, weights) {
        if (items.length !== weights.length) {
            throw new Error('Items and weights must be of the same size');
        }

        if (!items.length) {
            throw new Error('Items must not be empty');
        }

        // Preparing the cumulative weights array.
        // For example:
        // - weights = [1, 4, 3]
        // - cumulativeWeights = [1, 5, 8]
        const cumulativeWeights = [];
        for (let i = 0; i < weights.length; i += 1) {
            cumulativeWeights[i] = weights[i] + (cumulativeWeights[i - 1] || 0);
        }

        // Getting the random number in a range of [0...sum(weights)]
        // For example:
        // - weights = [1, 4, 3]
        // - maxCumulativeWeight = 8
        // - range for the random number is [0...8]
        const maxCumulativeWeight = cumulativeWeights[cumulativeWeights.length - 1];
        const randomNumber = maxCumulativeWeight * Math.random();

        // Picking the random item based on its weight.
        // The items with higher weight will be picked more often.
        for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
            if (cumulativeWeights[itemIndex] >= randomNumber) {
                return {
                    item: items[itemIndex],
                    index: itemIndex,
                };
            }
        }
    }

}

export default new AdsService();