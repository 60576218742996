import React from "react";
import { Button } from 'semantic-ui-react'
import "antd/dist/antd.css";
import { Table } from "antd";
import {
    sortableContainer,
    sortableElement,
    sortableHandle
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import authHeader from '../../services/auth-header';
const DragHandle = sortableHandle(({ active }) => (
    <MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class DragSong extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        dataSource: [],
        selectedItems: [],
    };

    componentDidMount() {
        console.log('Here');
        fetch(`https://mcqueeninc.net/api/playlist/getSongs/${this.props.data.id}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(res => res.json())
            .then((song) => {
                song.map((item, index) => {
                    this.setState({
                        dataSource: [...this.state.dataSource, {
                            index: index,
                            ...item
                        }]
                    })
                })

            },
                (error) => {
                });
    }
    getColumns() {
        return [
            {
                title: "Sort",
                dataIndex: "",
                width: 30,
                className: "drag-visible",
                render: (d, dd, i) => (
                    <>

                        <DragHandle active={this.state.selectedItems.includes(i)} />
                    </>
                )
            },
            {
                title: "Titulo",
                dataIndex: "Title",
                className: "drag-visible"
            },
            {
                title: "Artista",
                dataIndex: "Artist"
            },
            {
                title: "Genero",
                dataIndex: "Genre"
            }
        ];
    }
    merge(a, b, i = 0) {
        let aa = [...a];
        return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        const { dataSource, selectedItems } = this.state;
        let tempDataSource = dataSource;

        if (oldIndex !== newIndex) {
            if (!selectedItems.length) {
                let movingItem = tempDataSource[oldIndex]
                tempDataSource.splice(oldIndex, 1)
                tempDataSource = this.merge(tempDataSource, [movingItem], newIndex)

            } else {
                let filteredItems = [];
                selectedItems.forEach((d) => {
                    filteredItems.push(tempDataSource[d]);
                });
                let newData = [];
                tempDataSource.forEach((d, i) => {
                    if (!selectedItems.includes(i)) {
                        newData.push(d);
                    }
                });
                tempDataSource = [...newData];
                tempDataSource = this.merge(tempDataSource, filteredItems, newIndex);
            }
            
            this.setState({ dataSource: tempDataSource, selectedItems: [] });
            console.log('Sorted');
            console.log(this.state.dataSource);
            this.updateSongIndex();
        }
    };

    DraggableContainer = (props) => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { dataSource } = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"]
        );
        return (
            <SortableItem
                index={index}
                {...restProps}
                selected={this.state.selectedItems.length}
                onClick={(e) => {
                    console.log(this.state);
                    if (e.ctrlKey || e.metaKey) {
                        let { selectedItems } = this.state;
                        selectedItems.includes(index)
                            ? selectedItems.splice(
                                selectedItems.indexOf(index),
                                1
                            )
                            : selectedItems.push(index);
                        this.setState({ ...selectedItems })

                    } else { this.setState({ selectedItems: [] }) }
                }}
            />
        );
    };

    updateSongIndex = () => {
        console.log('Execute update');
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': authHeader()['x-access-token'] },
            body: JSON.stringify(this.state.dataSource),
            redirect: 'follow'
        };

        fetch("https://mcqueeninc.net/api/playlist/sortSongs", requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {

            })
            .catch(error => console.log('error', error));

    };

    render() {
        const { dataSource, selectedItems } = this.state;
        return (
            <>
                <h3>"CNTRL + Click" para seleccionar multiples canciones</h3>
                {/* <Button onClick={() => this.updateSongIndex()}>Actualizar</Button> */}
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={this.getColumns()}
                    rowKey="index"

                    components={{
                        body: {
                            wrapper: this.DraggableContainer,
                            row: this.DraggableBodyRow
                        }
                    }}
                />
                {selectedItems.length ? <>{selectedItems.length} canciones seleccionadas </> : ''}
            </>
        );
    }
}
export default DragSong;
// export default function DragSong(props) {

//     const [songs, setSongs] = useState([]);
//     const [selectedItems, setSelectedItems] = useState([]);
//     const SortableItem = sortableElement((props) => <tr {...props} />);
//     const SortableContainer = sortableContainer((props) => <tbody {...props} />);

//     const DragHandle = sortableHandle(({active}) => (
//         <MenuOutlined style={{ cursor: "grab", color: active ? "blue":"#999" }} />
//       ));

//     const handleOnDragEnd = (result) => {
//         const items = Array.from(songs);
//         const [reorderedItem] = items.splice(result.source.index, 1);
//         reorderedItem.SongIndex = result.destination.index;

//         items.splice(result.destination.index, 0, reorderedItem);

//         setSongs(items);

//     }

//     const getColumns = () => {
//         return [
//             {
//                 title: "Sort",
//                 dataIndex: "",
//                 width: 30,
//                 className: "drag-visible",
//                 render: (d, dd, i) => (
//                     <>

//                         <DragHandle active={this.state.selectedItems.includes(i)} />
//                     </>
//                 )
//             },
//             {
//                 title: "Titulo",
//                 dataIndex: "Title",
//                 className: "drag-visible"
//             },
//             {
//                 title: "Artist",
//                 dataIndex: "artist"
//             },
//             {
//                 title: "Address",
//                 dataIndex: "address"
//             }
//         ];
//     }

//     const updateSongIndex = () => {

//         var requestOptions = {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify(songs),
//             redirect: 'follow'
//         };

//         fetch("https://mcqueeninc.net/api/playlist/sortSongs", requestOptions)
//             .then(response => {
//                 response.text()

//             })
//             .then(result => {

//             })
//             .catch(error => console.log('error', error));

//     };



//     useEffect(() => {
//         console.log(props);
//         if (songs.length == 0) {
//             fetch(`https://mcqueeninc.net/api/playlist/getSongs/${props.data.id}`, {
//                 method: 'GET',
//             })
//                 .then(res => res.json())
//                 .then((song) => {
//                     setSongs(song)

//                 },
//                     (error) => {
//                     });
//         }
//     })

//     if (songs.length > 0)
//         return (<div>
//             <div>
//                 <Button primary onClick={() => { updateSongIndex(); props.onHide() }}>
//                     Actualizar
//                 </Button>
//             </div>
//             <DragDropContext onDragEnd={handleOnDragEnd}>
//                 <Table class="ui fixed table">
//                     <Table.Header>
//                         <Table.Row>
//                             {/* // {reorderEnabled && (<Table.HeaderCell />)} */}
//                             <Table.HeaderCell>Titulo</Table.HeaderCell>
//                             <Table.HeaderCell>Artista</Table.HeaderCell>
//                             {/* <Table.HeaderCell>Duracion</Table.HeaderCell> */}
//                             {/* <Table.HeaderCell>Action</Table.HeaderCell> */}
//                         </Table.Row>
//                     </Table.Header>

//                     <Droppable droppableId="songs">
//                         {(provided) => (
//                             <Ref innerRef={provided.innerRef}>
//                                 <Table.Body {...provided.droppableProps}>
//                                     {songs.map((data, index) => {
//                                         return (
//                                             <Draggable key={data.id} draggableId={data.Title} index={index}>
//                                                 {(provided) => (
//                                                     <Ref innerRef={provided.innerRef}>
//                                                         <Table.Row {...provided.draggableProps}
//                                                             {...provided.dragHandleProps}
//                                                             key={data.id}>
//                                                             <Table.Cell>{data.Title}</Table.Cell>
//                                                             <Table.Cell>{data.Artist}</Table.Cell>

//                                                         </Table.Row>
//                                                     </Ref>
//                                                 )}

//                                             </Draggable>
//                                         )
//                                     }
//                                     )}
//                                     {provided.placeholder}
//                                 </Table.Body>
//                             </Ref>
//                         )}
//                     </Droppable>
//                 </Table>
//             </DragDropContext>

//         </div>)

//     return <div>Loading...</div>
// }