import React, { useEffect, useState } from 'react'
import { Table, Button } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import authHeader from '../../services/auth-header';
import MaterialTable from "material-table";

export default function Read(props) {

    const [APIData, setAPIData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const history = useHistory();

    const getData = () => {
        axios.get(`https://mcqueeninc.net/api/song`, { headers: authHeader() })
            .then((response) => {
                response.data.sort((a, b) =>  b.id -a.id);
                console.log(response.data);
                setAPIData(response.data);
            })
    }
    const setData = (data) => {
        // console.log(data);
        let { id, Title, Artist, Duration, Genre, ReleaseDate, IsYoutube, YoutubeUrl, isBlocked, Src, SongImg,  } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('Title', Title);
        localStorage.setItem('Artist', Artist);
        localStorage.setItem('Genre', Genre);
        localStorage.setItem('Duration', Duration);
        localStorage.setItem('ReleaseDate', ReleaseDate);
        localStorage.setItem('IsYoutube', IsYoutube);
        localStorage.setItem('YoutubeUrl', YoutubeUrl);
        localStorage.setItem('isBlocked', isBlocked);
        localStorage.setItem('Src', Src);
        localStorage.setItem('SongImg', SongImg);
        

        history.push('/update');
    }

    const onDelete = (id) => {
        axios.delete(`https://mcqueeninc.net/api/song/${id}`, { headers: authHeader() })
            .then((response) => {
                console.log(response.data);
                getData();
            })
    }

    const applyFilter = (e) => {

        setSearchValue(e.target.value);
        let test = search(APIData);
        console.log(test);
    }

    function search(rows) {
        console.log(searchValue);
        return rows.filter((row) => row.Title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            || row.Artist.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
    }

    useEffect(() => {
        getData();
    }, [])

    return (

        <div>
            <div>
                <Link to={'/createSong'}>
                    <Button primary>
                        Subir canción
                    </Button>
                </Link>
            </div>
            <div>
                <br></br>
            </div>
            <div>
                <MaterialTable
                    title="Lista de canciones"
                    columns={[
                        { title: 'Titulo', field: 'Title' },
                        { title: 'Artista', field: 'Artist' },
                        { title: 'Duración', field: 'Duration' },
                        { title: 'Genero', field: 'Genre' },

                    ]}
                    data={APIData}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Editar canción',
                            onClick: (event, rowData) => setData(rowData)
                        },
                        {
                            icon: 'delete',
                            tooltip: 'eliminar canción',
                            onClick: (event, rowData) => onDelete(rowData.id)
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Acciones'
                        },

                        pagination: {
                            labelRowsSelect: 'lineas'
                        },
                        toolbar: {
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        },
                        body: {
                            emptyDataSourceMessage: 'No contenido para mostrar',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}>

                </MaterialTable>
            </div>

        </div>
    )
}