import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import authHeader from '../../services/auth-header';
import { styled } from '@mui/material/styles';
import {
    TextField,
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Button,
    InputLabel,
    Select
}
    from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Municipios from '../../JsonFiles/municipios.json'
import Provincias from '../../JsonFiles/provincias.json'
import Sectores from '../../JsonFiles/sectores.json'
import SaveIcon from '@mui/icons-material/Save';

import IntensityAdsForm from '../IntensityAdsForm/IntensityAdsForm';
import IntensityAdsZone from '../IntensityAdsZone/IntensityAdsZone';

import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function CreatePlaylist() {
    const history = useHistory();
    const [Loaded, SetLoaded] = useState(false);
    const [RoleApi, setAPIData] = useState([]);
    const [Categories, SetAPICategories] = useState([]);
    const [Id, setID] = useState('');

    const [fieldProvincias, setProvincias] = useState([]);
    const [fieldMunicipios, setMunicipios] = useState([]);
    const [fieldSectores, setSectores] = useState([]);
    const [playlist, setPlaylist] = useState(0);
    const [Users, setUsers] = useState([]);

    const [valueProvincias, setValProvincias] = useState('');
    const [valueMunicipios, setValMunicipios] = useState('');
    const [valueSectores, setValSectores] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileMp3, setSelectedFileMp3] = useState();
    const [category, setCategory] = useState([]);
    const [Title, setTitle] = useState('');
    const nameInputRef = useRef();
    const [userVal, setUserVal] = useState('');

    const [userExcep, setUserExcep] = useState(0);
    const [userSpecific, setUserSpecific] = useState(0);
    const [intensity, setIntensity] = useState(0);
    const [intensityByUser, setIntensityByUser] = useState([]);
    const [intensityInitValue, setIntensityInitValue] = useState([]);
    const [intensityByZone, setIntensityByZone] = useState([]);
    const [intensityZoneInitValue, setIntensityZoneInitValue] = useState([]);

    const [checkIntensityUser, setCheckIntensityUser] = useState(false);
    const [checkIntensityZone, setCheckIntensityZone] = useState(false);

    const Input = styled('input')({
        display: 'none',
    });

    const getUserIntensity = (IntensityInfo) => {
        setIntensityByUser(IntensityInfo);
    }
    const getZoneIntensity = (IntensityInfo) => {
        setIntensityByZone(IntensityInfo);
    }

    const handleChange = (event) => {
        setCheckIntensityUser(event.target.checked);
    };

    const handleChangeZone = (event) => {
        setCheckIntensityZone(event.target.checked);
    };

    function submitHandler(event) {
        event.preventDefault();
        handleSubmission();
    }
    const changeHandlerImg = (event) => {

        setSelectedFile(event.target.files[0]);

    };
    const changeHandlerMp3 = (event) => {

        setSelectedFileMp3(event.target.files[0]);

    };

    const getPlaylistData = () => {
        axios.get(`https://mcqueeninc.net/api/playlist`, { headers: authHeader() })
            .then((response) => {

                response.data.map(playlist => {
                    setAPIData(RoleApi => [...RoleApi, {
                        label: playlist.Name,
                        id: playlist.id
                    }]);
                })
            });

    }

    const getUsers = () => {
        axios.get(`https://mcqueeninc.net/api/users`, { headers: authHeader() })
            .then((response) => {

                response.data.map(user => {
                    setUsers(Users => [...Users, {
                        label: user.fullname,
                        id: user.id
                    }]);

                })

                SetLoaded(true);
            });
    }
    const handleSubmission = () => {
        var myHeaders = new Headers();
        console.log(userVal);
        myHeaders.append("Authorization", "Basic RGV2VXNlcjpEZXZlbG9wZXJzMTIzJA==");

        let requestJson = {
            Title: nameInputRef.current.value,

            ProvinciaId: valueProvincias,
            MunicipioId: valueMunicipios,
            DistritoMunicipalId: valueSectores,
            UserId: userVal

        }

        console.log();
        if (Array.isArray(category)) {

            requestJson = {
                ...requestJson,
                categories: category
            }
        }
        let headerCode = authHeader();
        var requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                headerCode
            },
            body: JSON.stringify(requestJson),
            redirect: 'follow'
        };
        // console.log(requestOptions);
        fetch(`https://mcqueeninc.net/api/ads/${Id}`, requestOptions)
            .then(response => {
                response.text()
                console.log(response);
                history.goBack();
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    const getCategoryData = () => {
        axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader() })
            .then((response) => {
                response.data.map(categ => {
                    SetAPICategories(Categorias => [...Categorias, {
                        label: categ.Name,
                        id: categ.id
                    }]);
                })
            })
    }

    const setProvinciasValue = (value) => {
        setValProvincias(value);
    }

    const getProvincias = () => {

        Provincias.map(prov => {
            setProvincias(fieldProvincias => [...fieldProvincias, {
                label: prov.provincia,
                id: prov.provincia_id
            }]);
        });
    }
    const setInit = () => {
        setID(localStorage.getItem('ID'));
        setTitle(localStorage.getItem('Title'));
        setUserVal(localStorage.getItem('UserId'));
        setPlaylist(localStorage.getItem('PlaylistId'));
        setCategory(localStorage.getItem('Categories'));
        setUserExcep(localStorage.getItem('UserExcep'));
        setUserSpecific(localStorage.getItem('UserSpecific'));
        if(localStorage.getItem('ProvinciaId'))
        setValProvincias(JSON.parse(localStorage.getItem('ProvinciaId')));

        if(localStorage.getItem('MunicipioId'))
        setValMunicipios(JSON.parse(localStorage.getItem('MunicipioId')));
        
        if(localStorage.getItem('SectorId'))
        setValSectores(JSON.parse(localStorage.getItem('SectorId')));
        setIntensity(localStorage.getItem("Intensity"));
        
        setIntensityByZone(localStorage.getItem('IntensityByZone'));
        setIntensityZoneInitValue(localStorage.getItem('IntensityByZone'));

        setIntensityByUser(localStorage.getItem('IntensityByUser'));
        setIntensityInitValue(localStorage.getItem('IntensityByUser'));
    }

    useEffect(() => {
        setInit();
        getPlaylistData();
        getCategoryData();
        getProvincias();
        getUsers();

    }, [])
    if (Loaded) {
        console.log(valueProvincias);
        return (
            <div>
                <form
                    autoComplete="off"
                    noValidate

                >
                    <Card>
                        <CardHeader
                            subheader="Actualizando anuncio"
                            title="Anuncios"
                        />
                        <Divider />
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                            >

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField required
                                        fullWidth
                                        id="outlined-basic"
                                        label="Titulo"
                                        value={Title}
                                        inputRef={nameInputRef}
                                        variant="outlined" />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete

                                        disablePortal
                                        id="users"
                                        options={Users}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Users) => Users.label ? Users.label : ''}
                                        value={Users[Users.findIndex(item => item.id == userVal)]}
                                        onChange={(event, value) => value ? setUserVal(value.id) : setUserVal('')}
                                        renderInput={(params) => <TextField {...params} label="Propietario" />}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="category"
                                        options={Categories}
                                        sx={{ width: 300 }}
                                        getOptionLabel={Categories => Categories.label}
                                        value={Categories.filter(item => category.includes(item.id))}
                                        onChange={(event, value) => value ? setCategory(value.map(item => item.id)) : setCategory('')}
                                        renderInput={(params) => <TextField {...params} label="Categoria" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Intensidad General</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="weight"
                                            defaultValue={50}
                                            value={intensity}
                                            label="Intensidad"
                                            onChange={(event) => setIntensity(event.target.value)}
                                        >
                                            <MenuItem value={0}>
                                                <em>Ninguna</em>
                                            </MenuItem>
                                            <MenuItem value={25}>Baja</MenuItem>
                                            <MenuItem value={50}>Media</MenuItem>
                                            <MenuItem value={75}>Alta</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="userSpecific"
                                        options={Users}
                                        sx={{ width: 300 }}
                                        getOptionLabel={Users => Users.label}
                                        value={Users.filter(item => userSpecific.includes(item.id))}
                                        onChange={(event, value) => value ? setUserSpecific(value.map(item => item.id)) : setUserSpecific('')}
                                        renderInput={(params) => <TextField {...params} label="Negocios especificos" />}
                                    />
                                </Grid>
                                {(!userSpecific || userSpecific == '' || userSpecific == '[]') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="userExcep"
                                        options={Users}
                                        sx={{ width: 300 }}
                                        getOptionLabel={Users => Users.label}
                                        value={Users.filter(item => userExcep.includes(item.id))}
                                        onChange={(event, value) => value ? setUserExcep(value.map(item => item.id)) : setUserExcep('')}
                                        renderInput={(params) => <TextField {...params} label="Negocios excluidos" />}
                                    />
                                </Grid>}
                                {(!userSpecific || userSpecific == '' || userSpecific == '[]') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="provincias"
                                        options={Provincias}
                                        sx={{ width: 300 }}
                                        // isOptionEqualToValue={(option, value) => option.provincia_id == value.provincia_id}
                                        getOptionLabel={option => option.provincia || ''}
                                        value={Provincias.filter(item => valueProvincias.includes(item.provincia_id))}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setProvinciasValue(value.map(item => item.provincia_id)) : setProvinciasValue('')}
                                        renderInput={(params) => <TextField {...params} label="Provincia" />}
                                    />

                                </Grid>}
                                {(!userSpecific || userSpecific == '' || userSpecific == '[]') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="municipios"
                                        options={Municipios.filter(item => {
                                            if (valueProvincias) {
                                                return valueProvincias.includes(item.provincia_id);
                                            } else {
                                                return '';
                                            }
                                        })}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Municipios) => Municipios.minicipio ? Municipios.minicipio : ''}
                                        // value={Municipios[Municipios.findIndex(item => item.minicipio_id == valueMunicipios)]}
                                        value={Municipios.filter(item => valueMunicipios.includes(item.minicipio_id))}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setValMunicipios(value.map(item => item.minicipio_id)) : setValMunicipios('')}
                                        renderInput={(params) => <TextField {...params} label="Municipio" />}
                                    />
                                </Grid>}
                                {(!userSpecific || userSpecific == '' || userSpecific == '[]') && <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        id="sectores"

                                        options={Sectores.filter(item => {
                                            if (valueMunicipios) {
                                                return valueMunicipios.includes(item.municipio_id);
                                            } else {
                                                return '';
                                            }
                                        })}
                                        renderOption={(props, option) => (

                                            <li {...props} key={option.sector_id}>
                                                {option.sector}
                                            </li>
                                        )}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Sectores) => Sectores.sector ? Sectores.sector : ''}
                                        // value={Sectores[Sectores.findIndex(item => item.sector_id == valueSectores)]}
                                        value={Sectores.filter(item => valueSectores.includes(item.sector_id))}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setValSectores(value.map(item => item.sector_id)) : setValSectores('')}
                                        renderInput={(params) => <TextField {...params} label="Sector" />}
                                    />
                                </Grid>}

                            </Grid>

                        </CardContent>

                        <Divider />



                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checkIntensityZone}
                                        onChange={handleChangeZone}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                label="Intensidad por Zona" />
                            {checkIntensityZone &&
                                <IntensityAdsZone
                                    Provincias={valueProvincias ? Provincias.filter(item => valueProvincias.includes(item.provincia_id)) : fieldProvincias}
                                    Municipios={valueMunicipios ? Municipios.filter(item => valueMunicipios.includes(item.minicipio_id)) : Municipios}
                                    Sectores={valueSectores != '[]' ? Sectores.filter(item => valueSectores.includes(item.sector_id)) : Sectores}
                                    handleIntensityInfo={getZoneIntensity}
                                    intensityByZone={intensityZoneInitValue}
                                >

                                </IntensityAdsZone>}

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checkIntensityUser}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                label="Intensidad por Usuario" />
                            {checkIntensityUser &&
                                <IntensityAdsForm
                                    Users={Users}
                                    handleIntensityInfo={getUserIntensity}
                                    intensityByUsers={intensityInitValue}
                                ></IntensityAdsForm>}
                        </FormGroup>
                        <Box

                            id='userForm'
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '45ch' },
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={submitHandler}

                        >
                            <div>

                                <Button type='submit' variant="contained" startIcon={<SaveIcon />}>Actualizar Anuncio</Button>
                            </div>

                        </Box>
                    </Card>
                </form>
            </div>
        )
    }
    else {

        return <div>Loading...</div>
    }
}

export default CreatePlaylist;