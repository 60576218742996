/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { findDOMNode } from 'react-dom';
import AdsService from "../../services/ads.service";
import ImageGallery from "react-image-gallery";
import ReactPlayer from 'react-player/lazy';
import AuthService from "../../services/auth.service";
import LogService from "../../services/log.service";
import screenfull from "screenfull";
import { Button }
    from '@mui/material';

const buttonStyle = {
    position: "relative",
    marginBlockEnd: "auto",
    marginRight: "5px"
}

class FullpageWrapper extends React.PureComponent {

    constructor(props) {
        super(props)
        this.user = AuthService.getCurrentUser();
      }

    state = {
        ads: [],
        current_ad: {},
        last_ad: {},
        duration: 0,
        doLoop: false
    }

    onLeave(origin, destination, direction) {
        console.log("Leaving section " + origin.index);
    }
    afterLoad(origin, destination, direction) {
        console.log("After load: " + destination.index);
    }

    async componentDidMount() {
        let ads = await AdsService.buildAds();
        console.log(ads);
        if (ads.length > 0) {

            this.setState({ ads: ads });
            this.setRandomAd();
            this.setState({ last_ad: this.state.current_ad });
        }
        if(ads.length == 1)
        this.setState({doLoop: true})

    }

    async setRandomAd() {
        // this.setState({current_ad : {}});
        console.log("Setting Ad");
        this.setState({ current_ad: await AdsService.getSubRandomAd() })

        if (this.state.current_ad.embedUrl == this.state.last_ad.embedUrl && this.state.ads.length > 1) {
            console.log("Refresh");
            this.setRandomAd();
        }
        else if (this.state.current_ad.embedUrl == this.state.last_ad.embedUrl && this.state.ads.length > 1){
            this.setState({doLoop:true});
        
        } else {
            console.log(this.state.last_ad);
            this.setState({ last_ad: this.state.current_ad });
            LogService.LogEvent("VIDEOON", this.user.username, `User ${this.user.username}`, this.state.current_ad.id, '1');
        }

    }

    handleDuration = (duration) => {
        console.log('onDuration', duration)
        this.setState({ duration })
    }

    componentDidUpdate() {

        if (this.state.current_ad.type && this.state.current_ad.type == 'banner') {
            setTimeout(() => {
                this.setRandomAd();
            }, 6000);
        }

    }

    handleEnded = () => {
        console.log('onEnded')
        this.setRandomAd();
    }

    handleClickFullscreen = () => {
        screenfull.request(findDOMNode(this.player))
    }

    ref = player => {
        this.player = player
    }
    render() {

        
        if (this.state.ads.length > 0) {
            console.log(this.state.current_ad);
            console.log(this.state.ads);
            return (

                <div className="App" >
                    
                    <Button onClick={this.handleClickFullscreen} style={buttonStyle} size="large" variant="outlined">Pantalla Completa</Button>
                    {this.state.current_ad.type == 'Video' && <ReactPlayer
                        ref={this.ref}
                        url={this.state.current_ad["embedUrl"]}w
                        playing={true}
                        loop={this.state.doLoop}
                        // controls={true}
                        muted={true}
                        width="1980px"
                        height="720px"
                        // style={{width: '100%', height: '100%',position: 'fixed', top: '0', left: '0'}}
                        onDuration={this.handleDuration}
                        onEnded={this.handleEnded}
                    >


                    </ReactPlayer>}
                </div>
            );
        }


        return (<div>Loading</div>)
    }
}

export default FullpageWrapper;
