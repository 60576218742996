import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import classes from './Playlist.module.css';
import Card from '../UI/Card';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import authHeader from '../../services/auth-header';
function UpdatePlaylist() {

    const history = useHistory();
    const [id, setID] = useState(null);
    const [Name, setName] = useState('');
    const [Loaded, SetLoaded] = useState(false);

    const [Categorias, setCategorias] = useState([]);
    const [CategoryId, setCategoryId] = useState(0);

    function submitHandler(event) {
        event.preventDefault();
        handleSubmission();
    }

    const getData = async () => {
        const ApiCategories = await axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader()});


        ApiCategories.data.map(categ => {
            setCategorias(Categorias => [...Categorias, {
                label: categ.Name,
                id: categ.id
            }]);
            console.log(Categorias);
        });
        SetLoaded(true);
    }

    const handleSubmission = () => {

        var requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'x-access-token': authHeader()['x-access-token'] },
            body: JSON.stringify({
                Name: Name,
                CategoryId: CategoryId
            }),
            redirect: 'follow'
        };

        console.log(requestOptions.body);

        fetch(`https://mcqueeninc.net/api/playlist/${id}`, requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {

                console.log(result)
                history.goBack();
            })
            .catch(error => console.log('error', error));

    }

    const setDataInit = () => {
        setID(localStorage.getItem('ID'))
        setName(localStorage.getItem('Name'));
        setCategoryId(localStorage.getItem('CategoryId'));
    }

    useEffect(() => {
        setDataInit();
        getData();

    }, []);

    if (Loaded) {
        return (
            <Card>
                <div>
                    <form id='playlistForm' className={classes.form} onSubmit={submitHandler}>
                        <div className={classes.control}>
                            <label htmlFor='Name'>Nombre de la Lista de Reproducción</label>
                            <input type='text' required id='name' value={Name} onChange={((e) => setName(e.target.value))} />
                        </div>
                        <div>
                            <Autocomplete
                                disablePortal
                                id="category"
                                options={Categorias}
                                sx={{ width: 300 }}
                                getOptionLabel={Categorias => Categorias.label}
                                value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                onChange={(event, value) => setCategoryId(value.id)}
                                renderInput={(params) => <TextField {...params} label="Categorias" />}
                            />
                        </div>
                        <div className={classes.actions}>
                            <button>Actualizar lista de reproducción</button>
                        </div>
                    </form>
                </div>
            </Card>
        )
    }
    else {
        return <div>Loading...</div>
    }


}

export default UpdatePlaylist;