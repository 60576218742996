import React, { useRef, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import classes from './Playlist.module.css';
import Card from '../UI/Card';
import axios from 'axios';
import authHeader from '../../services/auth-header';
function CreatePlaylist() {

    const [Categorias, setCategorias] = useState([]);
    const [CategoryId, setCategoryId] = useState(0);
    const nameInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        handleSubmission();
    }

    const handleSubmission = () => {

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': authHeader()['x-access-token'] },
            body: JSON.stringify({
                Name: nameInputRef.current.value,
                CategoryId: CategoryId
            }),
            redirect: 'follow'
        };

        fetch("https://mcqueeninc.net/api/playlist", requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {
                document.getElementById('playlistForm').reset();

            })
            .catch(error => console.log('error', error));

    }

    const getData = async () => {
        const ApiCategories = await axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader()});
        console.log(ApiCategories);

        ApiCategories.data.map(categ => {
            setCategorias(Categorias => [...Categorias, {
                label: categ.Name,
                id: categ.id
            }]);
        });
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Card>
            <div>
                <form id='playlistForm' className={classes.form} onSubmit={submitHandler}>
                    <div className={classes.control}>
                        <label htmlFor='Name'>Nombre de la Lista de Reproducción</label>
                        <input type='text' required id='name' ref={nameInputRef} />
                    </div>
                    <div>
                        <Autocomplete
                            disablePortal
                            id="category"
                            options={Categorias}
                            
                            sx={{ width: 300 }}
                            onChange={(event, value) => setCategoryId(value.id)}
                            renderInput={(params) => <TextField {...params} label="Categorias" />}
                        />
                    </div>

                    <div className={classes.actions}>
                        <button>Add Song</button>
                    </div>
                </form>
            </div>
        </Card>
    )

}

export default CreatePlaylist;