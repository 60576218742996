import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Table, Button } from 'semantic-ui-react';
import axios from 'axios';
import authHeader from '../../services/auth-header';
export default function SongList(props) {

    const [APIData, setAPIData] = useState([]);

    const getPlaylistSongData = (id) => {
        axios.get(`https://mcqueeninc.net/api/playlist/getSongs/${id}`, {headers: authHeader()})
            .then((response) => {
                setAPIData(response.data);
            })
    }

    useEffect(() => {
        if (props.show) {
            getPlaylistSongData(props.data.id);
        }
    }, [props, props.data.id, props.data.show]);

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size='lg'>
            <Modal.Header closeButton>

                <Modal.Title id="contained-modal-title-vcenter">
                    Detalle de la lista "{props.data.Name}"
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Table class="ui fixed table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Canción</Table.HeaderCell>
                            <Table.HeaderCell>Artista</Table.HeaderCell>
                            {/* <Table.HeaderCell></Table.HeaderCell> */}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            APIData.map((data) => {

                                return (<Table.Row key={data.id}>
                                    <Table.Cell>{data.id}</Table.Cell>
                                    <Table.Cell>{data.Title}</Table.Cell>
                                    <Table.Cell>{data.Artist}</Table.Cell>
                                    {/* <Table.Cell>{button}</Table.Cell> */}
                                </Table.Row>)
                            })
                        }
                    </Table.Body>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
} 