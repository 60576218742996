import React, { useEffect, useState } from 'react'
import axios from 'axios';
import MaterialTable from "material-table";
import authHeader from '../../services/auth-header';

export default function UserTracking() {

    const [userData, setUserData] = useState([]);
    const [Loaded, setLoaded] = useState(false);
    const [refreshInterval, setRefreshInterval] = useState(10000)

    const getData = async () => {

        const APIdata = await axios.get(`https://mcqueeninc.net/api/users`, { headers: authHeader() })

        const SongsData = await axios.get(`https://mcqueeninc.net/api/song`, { headers: authHeader() });

        const LogData = await axios.get(`https://mcqueeninc.net/api/logs`, { headers: authHeader() });

        setUserData([]);
        APIdata.data.map(items => {
            console.log(LogData);
            const UserLogs = LogData.data.song.filter(log => log.Username == items.username);
            const lastRecord = UserLogs.splice(-1);
            let action, songName, userState, lastActivity;

            if (lastRecord.length > 0) {


                switch (lastRecord[0].Type) {
                    case 'PLAYON':
                        action = 'Tocando'
                        break;
                    case 'PLAYSTOP':
                        action = 'Pausado'
                        break;
                    case 'LOGIN':
                        action = 'Inicio de sesión'
                        break;
                    default:
                        action = 'Sin acciones'
                        break;
                }

                let Song = SongsData.data.find(song => song.id == lastRecord[0].SongId);
                songName = Song ? Song.Title : '';

                var newDate = new Date(lastRecord[0].createdAt);

                let today = new Date();

                var difDates = Math.round((Math.abs(today - newDate) / 1000) / 60);
                console.log(difDates);

                if (difDates > 10 && difDates < 59) {
                    userState = `Ultima actividad hace ${difDates} minutos`
                } else if (difDates > 60) {
                    userState = `Ultima activad hace ${Math.round(difDates / 60)} ${(Math.round(difDates / 60) > 1) ? 'horas' : 'hora'}`;
                }
                else {
                    userState = 'Conectado';
                }

            }

            setUserData(userData => [...userData, {
                id: items.id,
                username: items.username,
                fullname: items.fullname,
                action: action,
                songname: songName,
                UserState: userState,
                LastActivity: lastActivity
            }])
        });

        setLoaded(true);

    }

    useEffect(() => {
        getData();
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(getData, refreshInterval);
            return () => clearInterval(interval);

        }


    }, [refreshInterval])

    if (Loaded) {
        return (

            <div>
                <MaterialTable
                    title="Seguimiento de usuarios"
                    columns={[
                        { title: 'Usuario', field: 'username' },
                        { title: 'Nombre', field: 'fullname' },
                        { title: 'Actividad', field: 'action' },
                        { title: 'Canción', field: 'songname' },
                        { title: 'Estado', field: 'UserState' },

                    ]}
                    data={userData}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontSize: '14px',
                            fontWeight: 'bold'
                        },
                        pageSize: 10
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay contenido para mostrar',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        },
                        pagination: {
                            labelRowsSelect: 'lineas'
                        },
                        toolbar: {
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        }
                    }}>

                </MaterialTable>
            </div>
        )
    }
    else {
        return (
            <div>
                Loading...
            </div>
        )
    }
}