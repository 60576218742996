import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import classes from './users.module.css';
import Toast from 'react-bootstrap/Toast'
import { TextField, Autocomplete, Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import Municipios from '../../JsonFiles/municipios.json';
import Provincias from '../../JsonFiles/provincias.json';
import Sectores from '../../JsonFiles/sectores.json';
import authHeader from '../../services/auth-header';

function CreatePlaylist() {
    const history = useHistory();
    const [Loaded, SetLoaded] = useState(false);
    const [RoleApi, setAPIData] = useState([]);
    const [Categories, SetAPICategories] = useState([]);
    const [defaultRoles]
        = useState([{ label: "User", id: "user" }, { label: "Moderador", id: "moderator" }, { label: "Admin", id: "admin" }]);

    const [defaultTypes] = useState([{ label: "Reproductor de Musica", id: "music" }, { label: "Reproductor de Videos", id: "video" }]);

    const [fieldProvincias, setProvincias] = useState([]);
    const [fieldMunicipios, setMunicipios] = useState([]);
    const [fieldSectores, setSectores] = useState([]);
    const [playlist, setPlaylist] = useState(0);

    const [valueProvincias, setValProvincias] = useState('');
    const [valueMunicipios, setValMunicipios] = useState('');
    const [valueSectores, setValSectores] = useState('');
    const [category, setCategory] = useState(0);
    const [showToast, setToast] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userType, setUserType] = useState('');
    const userNameInputRef = useRef();
    const fullNameInputRef = useRef();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        handleSubmission();
    }

    const getPlaylistData = () => {
        axios.get(`https://mcqueeninc.net/api/playlist`, { headers: authHeader() })
            .then((response) => {

                response.data.map(playlist => {
                    setAPIData(RoleApi => [...RoleApi, {
                        label: playlist.Name,
                        id: playlist.id
                    }]);
                })
            });

    }

    const handleSubmission = () => {
        let headerCode = authHeader();
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 
                        'x-access-token':  headerCode["x-access-token"]  },
            body: JSON.stringify({
                username: userNameInputRef.current.value,
                email: emailInputRef.current.value,
                fullname: fullNameInputRef.current.value,
                playlistId: playlist,
                password: passwordInputRef.current.value,
                roles: roles,
                userType: userType,
                categoryId: category,
                ProvinciaId: valueProvincias,
                MunicipioId: valueMunicipios,
                DistritoMunicipalId: valueSectores
            }),
            redirect: 'follow'
        };

        fetch("https://mcqueeninc.net/api/auth/signup", requestOptions)
            .then(response => {
                response.text()

            })
            .then(result => {
                document.getElementById('userForm').reset();
                console.log(result);
                setToast(true);
                history.goBack();
            })
            .catch(error => console.log('error', error));

    }

    const getCategoryData = () => {
        axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader() })
            .then((response) => {
                response.data.map(categ => {
                    SetAPICategories(Categorias => [...Categorias, {
                        label: categ.Name,
                        id: categ.id
                    }]);
                })

            })
    }

    const setProvinciasValue = (value) => {
        setValProvincias(value);
    }

    const getProvincias = () => {

        Provincias.map(prov => {
            setProvincias(fieldProvincias => [...fieldProvincias, {
                label: prov.provincia,
                id: prov.provincia_id
            }]);
        });
    }

    useEffect(() => {
        getPlaylistData();
        getCategoryData();
        getProvincias();

    }, [])
    if (fieldMunicipios)
        return (
            <div>
                <form
                    autoComplete="off"
                    noValidate

                >
                    <Card>
                        <CardHeader
                            subheader="Agregando un nuevo usuario"
                            title="Usuarios"
                        />
                        <Divider />
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                            >

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField required
                                        fullWidth
                                        id="outlined-basic"
                                        label="Nombre de usuario"
                                        inputRef={userNameInputRef}
                                        variant="outlined" />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField required
                                        fullWidth
                                        id="outlined-basic"
                                        label="Nombre completo"
                                        inputRef={fullNameInputRef}
                                        variant="outlined" />

                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField required
                                        fullWidth
                                        id="outlined-basic"
                                        label="Correo electronico"
                                        inputRef={emailInputRef}
                                        variant="outlined" />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField required
                                        fullWidth
                                        id="outlined-basic"
                                        type='password'
                                        label="Contraseña"
                                        inputRef={passwordInputRef}
                                        variant="outlined" />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="Roles"
                                        options={defaultRoles}
                                        sx={{ width: 300 }}
                                        getOptionLabel={defaultRoles => defaultRoles.label}
                                        //value={RoleApi[RoleApi.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setRoles([value.id])}
                                        renderInput={(params) => <TextField {...params} label="Rol" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="Types"
                                        options={defaultTypes}
                                        sx={{ width: 300 }}
                                        getOptionLabel={defaultTypes => defaultTypes.label}
                                        //value={RoleApi[RoleApi.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => value ? setUserType(value.id) : setUserType([])}
                                        renderInput={(params) => <TextField {...params} label="Tipo de usuario" />}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="category"
                                        options={RoleApi}
                                        sx={{ width: 300 }}
                                        getOptionLabel={RoleApi => RoleApi.label}
                                        //value={RoleApi[RoleApi.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setPlaylist(value.id)}
                                        renderInput={(params) => <TextField {...params} label="Lista de reproducción" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="category"
                                        options={Categories}
                                        sx={{ width: 300 }}
                                        getOptionLabel={Categories => Categories.label}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setCategory(value.id)}
                                        renderInput={(params) => <TextField {...params} label="Categoria" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="provincias"
                                        options={fieldProvincias}
                                        sx={{ width: 300 }}
                                        getOptionLabel={fieldProvincias => fieldProvincias.label}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setProvinciasValue(value.id)}
                                        renderInput={(params) => <TextField {...params} label="Provincia" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="municipios"
                                        options={Municipios.filter(item => {
                                            if (valueProvincias) {
                                                return item.provincia_id === valueProvincias;
                                            } else {
                                                return '';
                                            }
                                        })}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Municipios) => Municipios.minicipio ? Municipios.minicipio : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setValMunicipios(value.minicipio_id)}
                                        renderInput={(params) => <TextField {...params} label="Municipio" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="sectores"

                                        options={Sectores.filter(item => {
                                            if (valueMunicipios) {
                                                return item.municipio_id === valueMunicipios;
                                            } else {
                                                return '';
                                            }
                                        })}
                                        renderOption={(props, option) => (

                                            <li {...props} key={option.sector_id}>
                                                {option.sector}
                                            </li>
                                        )}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(Sectores) => Sectores.sector ? Sectores.sector : ''}
                                        //value={Categorias[Categorias.findIndex(item => item.id == CategoryId)]}
                                        onChange={(event, value) => setValSectores(value.sector_id)}
                                        renderInput={(params) => <TextField {...params} label="Sector" />}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box

                            id='userForm'
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '45ch' },
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={submitHandler}

                        >
                            <div className={classes.actions}>

                                <button>Agregar usuario</button>
                            </div>
                            {/* </form> */}

                            <Toast show={showToast} onClose={() => setToast(false)} delay={3000}
                                bg='primary'>
                                <Toast.Header>
                                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                                    <strong className="me-auto">Usuario agregado!</strong>
                                    <small>1 sec ago</small>
                                </Toast.Header>

                            </Toast>
                        </Box>
                    </Card>
                </form>
            </div>
        )

    return <div>Loading...</div>

}

export default CreatePlaylist;