import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import DragSong from '../DragableSong/Drag'
import { Table, Button } from 'semantic-ui-react';
import './Style.css'
export default function SortSongs(props) {


    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size='lg'>
            <Modal.Header closeButton>

                <Modal.Title id="contained-modal-title-vcenter">
                    Organizar canciones de lista  "{props.data.Name}"
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className="show-grid">
                <DragSong {...props}></DragSong>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )

}