import React, { useEffect, useState } from 'react'
import { Table, Button } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import MaterialTable from "material-table";
import authHeader from '../../services/auth-header';
export default function Read() {

    const setData = (data) => {

        let { id, username, email, fullname, roles, playlistid, categoryId, ProvinciaId, MunicipioId, SectorId, userType } = data;
        console.log(data);
        localStorage.setItem('ID', id);
        localStorage.setItem('Username', username);
        localStorage.setItem('Email', email);
        localStorage.setItem('Fullname', fullname);
        localStorage.setItem('UserType', userType);
        localStorage.setItem('Roles', roles);
        localStorage.setItem('PlaylistId', playlistid);
        localStorage.setItem('CategoryId', categoryId);
        localStorage.setItem('ProvinciaId', ProvinciaId);
        localStorage.setItem('MunicipioId', MunicipioId);
        localStorage.setItem('SectorId', SectorId); 
        
        history.push('/users/update');

    }
    const history = useHistory();
    const [userData, setUserData] = useState([]);
    const [Loaded, setLoaded] = useState(false);

    const getData = async () => {
        const APIdata = await axios.get(`https://mcqueeninc.net/api/users`, { headers: authHeader() })
        console.log('New data:', APIdata);

        const PlData = await axios.get(`https://mcqueeninc.net/api/playlist`, { headers: authHeader() });
        const CategData = await axios.get(`https://mcqueeninc.net/api/category`, { headers: authHeader() });

        APIdata.data.map(items => {
            const Name = PlData.data.find(item => item.id === items.playlistid);
            let CategName = CategData.data.find(item => item.id === items.categoryId);

            console.log(CategName);

            if (!CategName) {
                CategName = { name: 'Ninguna' }
            }
            
            if(items.roles.length == 0){
                items.roles.push({
                    name: 'none'
                })
            }

            setUserData(userData => [...userData, {
                id: items.id,
                email: items.email,
                username: items.username,
                fullname: items.fullname,
                playlist: Name ? Name.Name : '',
                playlistid: items.playlistid,
                userType: items.userType,
                roles: items.roles[0].name,
                categoryId: items.categoryId,
                category: CategName.Name,
                ProvinciaId: items.ProvinciaId,
                MunicipioId: items.MunicipioId,
                SectorId: items.DistritoMunicipalId
            }])
        });

        setLoaded(true);

    }

    const onDelete = (id) => {
        axios.delete(`https://mcqueeninc.net/api/users/${id}`, { headers: authHeader() })
            .then((response) => {
                setUserData([]);
                getData();
            })
    }

    useEffect(() => {
        getData();

    }, [])

    if (Loaded) {
        return (

            <div>
                <div>
                    <Link to={'/users/register'}>
                        <Button primary>
                            Crear usuario
                        </Button>
                    </Link>
                </div>
                <div>
                    <br></br>
                </div>
                <MaterialTable
                    title="Lista de usuarios"
                    columns={[
                        { title: 'Usuario', field: 'username' },
                        { title: 'Nombre', field: 'fullname' },
                        { title: 'Correo', field: 'email' },
                        { title: 'Lista de reproducción', field: 'playlist' },
                        { title: 'Categoria', field: 'category' },

                    ]}
                    data={userData}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Editar usuario',
                            onClick: (event, rowData) => setData(rowData)
                        },
                        {
                            icon: 'delete',
                            tooltip: 'eliminar usuario',
                            onClick: (event, rowData) => onDelete(rowData.id)
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay contenido para mostrar',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        },
                        pagination: {
                            labelRowsSelect: 'lineas'
                        },
                        toolbar: {
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        }
                    }}>

                </MaterialTable>
            </div>
        )
    }
    else {
        return (
            <div>
                Loading...
            </div>
        )
    }
}