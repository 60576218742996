
import React, { useState, useEffect } from 'react';
import MusicPlayer from './Player/NewPlayer';
import base64 from 'base-64'
import AuthService from "../services/auth.service";
import Util from "../services/Util"
import authHeader from '../services/auth-header';
import "react-image-gallery/styles/css/image-gallery.css";
import EventBus from '../common/EventBus';
import axios from 'axios';
import adsService from '../services/ads.service';


function App() {

  const [songs, setItems] = useState([]);
  const [allSongs, setallSongs] = useState([]);
  const [allSongsList, setallSongsList] = useState([]);
  const [playerSongs, setSongs] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [noAds, setNoAds] = useState(false)
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);

  const [userOptions, setUserOptions] = useState({
    play: true,
    seeked: false,
    remove: false,
    destroy: false,
    admin: false
  });
  const [AdsItems, setAdsItems] = useState([]);
  const [AdsPlay, setAdsPlay] = useState([]);

  const url = "https://mcqueeninc.net/api/song";
  const user = AuthService.getCurrentUser();

  const setAdsUser = async () => {
    getAllSongs();

    let AdsGeneral = await axios.get('https://mcqueeninc.net/api/song?Ads=true', { headers: authHeader() })
      .catch(function (error) {

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      });

    let AdsSpecific = { data: [] };
    AdsSpecific = AdsGeneral.data.filter((ads) => ads.UserSpecific && ads.UserSpecific.includes(user.id));

    AdsGeneral.data = AdsGeneral.data.filter((ads) => ads.categories == '[]' && ads.UserSpecific == '[]' && ads.MunicipioId == '[]' && ads.ProvinciaId == '[]' && ads.DistritoMunicipalId == '[]');

    if (!AdsGeneral.data)
      AdsGeneral.data = [];

    if (!AdsSpecific.data)
      AdsSpecific.data = []

    axios.get(`https://mcqueeninc.net/api/song?Ads=true`, { headers: authHeader() })
      .then((response) => {

        const AdsByCategory = response.data.filter(ads => {

          if (ads.categories && ads.categories.includes(user.categoryId) && ads.isBlocked) {

            return ads;
          }
        });
        const AdsByCategoryOnly = response.data.filter(ads => {

          if (ads.categories
            && JSON.parse(ads.categories).includes(user.categoryId)
            && ads.isBlocked
            && ads.ProvinciaId == '[]' && ads.MunicipioId == '[]' && ads.DistritoMunicipalId == '[]') {
            return ads;
          }
        });

        // Anuncios por region y categoria
        let AdsByProvincia = AdsByCategory.filter(ads => ads.ProvinciaId && ads.ProvinciaId.includes(user.ProvinciaId) && ads.isBlocked && ads.MunicipioId == '[]' && ads.DistritoMunicipalId == '[]');

        let AdsByMunicipio = AdsByCategory.filter(ads => ads.ProvinciaId && ads.ProvinciaId.includes(user.ProvinciaId) && ads.MunicipioId && ads.MunicipioId.includes(user.MunicipioId) && ads.isBlocked && JSON.parse(ads.DistritoMunicipalId) == '[]');

        let AdsBySector = AdsByCategory.filter(ads => ads.DistritoMunicipalId && ads.DistritoMunicipalId.includes(user.DistritoMunicipalId) && ads.isBlocked);


        // Anuncios por region sin categoria.
        let AdsByProvinciaNotCat = response.data.filter(ads => ads.categories == '[]' && ads.ProvinciaId && ads.ProvinciaId.includes(user.ProvinciaId)
          && ads.isBlocked && JSON.parse(ads.MunicipioId) == '[]' && JSON.parse(ads.DistritoMunicipalId) == '[]');

        let AdsByMunicipioNotCat = response.data.filter(ads => ads.categories == '[]' && ads.MunicipioId && ads.MunicipioId.includes(user.MunicipioId) && JSON.parse(ads.DistritoMunicipalId) == '[]' && ads.isBlocked);

        let AdsBySectorNotCat = response.data.filter(ads => ads.categories == '[]' && ads.DistritoMunicipalId && ads.DistritoMunicipalId.includes(user.DistritoMunicipalId) && ads.isBlocked);

        let concatAds = AdsByMunicipio.concat(AdsByProvinciaNotCat, AdsByMunicipioNotCat,
          AdsBySectorNotCat, AdsByProvincia, AdsByMunicipio, AdsBySector, AdsByCategoryOnly, AdsGeneral.data, AdsSpecific);

        let AllAds = [...new Set(concatAds)].filter(ads => !ads.UserExcep || !ads.UserExcep.includes(user.id));

        if (AllAds.length == 0) {
          setNoAds(true);
        }

        setAdsItems(AllAds);

      })
      .catch(function (error) {

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      });
  }

  const getSongByPlaylist = async (id) => {

    const song = await axios.get(`https://mcqueeninc.net/api/playlist/getSongs/${id}`, { headers: authHeader() })
      .catch(function (error) {

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      });

    if (user) {
      const shuffledSongs = await Util.SongShuffle(song.data);
      setItems(shuffledSongs);
      SongsMap(shuffledSongs, AdsItems);
    }
    else {
      setItems(song.data);
      SongsMap(song.data, AdsItems);
    }

    setIsLoaded(true);

  }

  const SongsMap = (data, ads = [], allSongs) => {

    if (ads.length > 0) {
      ads.map(ad => {

        setAdsPlay(AdsPlay => [...AdsPlay,
        {
          name: ad.Title,
          singer: ad.Artist,
          cover: ad.SongImg,
          musicSrc: ad.Src
        }]);

      });
    }

    data.map((song, index) => {

      if (index > 0 && index % 3 == 0 && ads.length > 0) {
        // const randomAd = Math.floor(Math.random() * ads.length);
        const adPlay = adsService.getRandomAd(ads);

        setSongs(playerSongs => [...playerSongs,
        {
          name: adPlay.Title,
          singer: adPlay.Artist,
          cover: adPlay.SongImg,
          musicSrc: adPlay.Src
        }]);
      }
      if (allSongs) {
        setallSongsList(playerSongs => [...playerSongs,
        {
          id: song.id,
          name: song.Title,
          singer: song.Artist,
          cover: 'https://mcqueeninc.net/images/BG-Image.jpg',
          musicSrc: song.Src
        }]);
      } else {


        setSongs(playerSongs => [...playerSongs,
        {
          name: song.Title,
          singer: song.Artist,
          cover: 'https://mcqueeninc.net/images/BG-Image.jpg',
          musicSrc: song.Src
        }]);

      }
      setImages(images => [...images,
      {
        original: song.SongImg,
        thumbnail: song.SongImg
      }
      ]);

    });
  }

  const getAllSongs = () => {
    axios.get(url, { headers: authHeader() })
    fetch(url, {
      method: 'GET',
      headers: authHeader(),
    })
      .then(res => res.json())
      .then((song) => {

        console.log(song);
        setallSongs(song);

        SongsMap(song, [], true);
        // setIsLoaded(true);

      },
        (error) => {
          setIsLoaded(true);
          setError(error)
        });
  }

  useEffect(() => {
    const executeProcess = async () => {
      if (user) {

        if (AdsItems.length == 0 && !noAds)
          setAdsUser();
        if (AdsItems.length > 0 || noAds) {
          getSongByPlaylist(user.playlistid);
          // getAllSongs();
        }

        if (user.roles.includes("ROLE_ADMIN")) {
          setUserOptions({
            play: true,
            seeked: true,
            remove: true,
            destroy: true,
            admin: true
          })
        }
      }
      else {
        // getAllSongs();
      }

    }

    executeProcess();

  }, [AdsItems]);
  if (error) return <div> Error: {error.message}</div>;

  else if (!isLoaded) {
    return <div></div>
  } else {
    // console.log(allSongsList);
    return (

      <div className="App">
        <MusicPlayer
          user={user}
          originalAllSongs={allSongs}
          allSongs={allSongsList}
          audioList={playerSongs}
          originalList={songs}
          AdsList={AdsItems}
          AdsPlay={AdsPlay}
          play={userOptions.play}
          seeked={userOptions.seeked}
          remove={userOptions.remove}
          destroy={userOptions.destroy}
          admin={userOptions.admin}></MusicPlayer>
      </div>
    );
  }
}

export default App;
