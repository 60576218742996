import authHeader from '../services/auth-header';
import { getAudioDurationInSeconds } from '@remotion/media-utils';

class Util {

    SongShuffle(songs, qty, genres = []) {
        let times = 10;
        let newArrayOfSongs = [];
        let mGenresArray = [];
        let previous;
        if (songs.length > 0) {

            let arrayOfSongs = songs;
            if (qty) {
                times = qty;
            }

            if (genres.length > 0) {
                mGenresArray = genres;
            }
            else {
                
                mGenresArray = songs.map(song => song.Genre);
            }

            mGenresArray.forEach(genre => {

                let randomSong = arrayOfSongs
                    .filter(song => song.Genre == genre) //Filter by Genre
                    .sort(() => 0.5 - Math.random()) //Random sort within the genre
                    .slice(0, 1);

                let index = arrayOfSongs.findIndex(obj => obj.SongId == randomSong[0].SongId); //Index seach
                arrayOfSongs.splice(index, 1); // Delete original array

                newArrayOfSongs = [...newArrayOfSongs, ...randomSong];
            });

            // console.log(newArrayOfSongs);
            return newArrayOfSongs;

        }
        else {
            return false;
        }

    }

    updateSongsDuration = async (songs) => {

        

        if(!songs) return false;

        songs = songs.filter(song => song.Duration == 300);

        console.log(songs);
        for(const song of songs){
            const remote = await getAudioDurationInSeconds(song.Src);
            console.log(remote);
            const id = song.id;
            var requestOptions = {
                method: 'PUT',
                headers: {'Content-Type': 'application/json', 'x-access-token': authHeader()['x-access-token']},
                body: JSON.stringify({
                  Title: song.Title,
                  Genre: song.Genre,
                  Artist: song.Artist,
                  Duration: Math.round(remote),
                //   YoutubeUrl: youtubeUrlInputRef.current.value,
                //   isBlocked: Blocked,
                }),
                redirect: 'follow'
              };
              console.log(song);
              fetch(`https://mcqueeninc.net/api/song/${id}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log(result);
                }
                )
                .catch(error => console.log('error', error));
        }

    }
}

export default new Util();